import TaskerDashboard from "./TaskerDashboard";

const TaskerDashboardPage = () => {
  return (
    <div>
      <TaskerDashboard />
    </div>
  );
};

export default TaskerDashboardPage;
