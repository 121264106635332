import React from "react";

import "./styles.css";

const NoDataState = () => (
  <div className="state-wrapper">
    <div className="state-content">
      <h2 className="state-title">No Data Available</h2>
      <p className="state-message">
        We couldn't find any dashboard data to display.
      </p>
      <button onClick={() => window.location.reload()} className="state-button">
        Refresh
      </button>
    </div>
  </div>
);

export default NoDataState;
