import React, { useState, useEffect } from "react";
import CountryList from "./elements/dropdowns/CountryList";
import AddressForm from "./UserLocation";
import Education from "./Education";
import UserAsset from "./UserAsset";
import AllLanguageList from "./elements/dropdowns/AllLanguageList";
import FullpageLoader from "./elements/FullpageLoader";
import api from "./api";
import {
    LogoName,
    InfoHeading,
    StepCount,
    InputContainer,
    NextBtn,
    PreviousBtn,
    AddLangBtn,
    SkillContainer,
    LanguageCheckbox,
} from "./Style";
import Logo from "./Image/logo2.PNG";
import { showNotification } from "./actions/index.actions";
const RegistrationPage = () => {
    const country = {
        id: "6",
        countryName: "India",
        countryCode: "IND",
    };
    const [formData, setFormData] = useState({
        // 1st screen data
        country: country,
        countryCode: country.countryCode, // derive from country
        number: "",
        type: "otp",
        language: null,
        otp: "",
        // 2nd screen data
        gender: "",
        salutation: "",
        // 3rd screen data
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        age: "",
        email: "",
        // 4th screen data AddressForm fields
        level1: null,
        level2: null,
        level3: null,
        level4: null,
        level5: null,
        levels: null,
        level1New: "",
        level2New: "",
        level3New: "",
        level4New: "",
        level5New: "",
        location: "",
        addressLandmark: "",
        // 5th Screen Education fields
        educationLevel: null,
        fieldOfStudy: null,
        profession: null,
        educationLevelInput: "",
        fieldOfStudyInput: "",
        professionInput: "",
        languages: [],
        // 6h Screen UserAsset fields
        smartphone: false,
        tablet: false,
        computer: false,
        internet: false,
        vehicle: false,
        smartphoneOptions: [],
        tabletOptions: [],
        computerOptions: [],
        internetOptions: [],
        vehicleOptions: [],
        computerOSOptions: [],
    });

    // // lat lon code
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [locationGranted, setLocationGranted] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [locationBlocked, setLocationBlocked] = useState(false);
    const [isLocationLoading, setLocationLoading] = useState(false);
    // Function to request location access from the user
    const requestLocation = () => {
        setLocationLoading(true); // Set loading to true to avoid any API call until location is handled
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    setLocationGranted(true);
                    setLocationBlocked(false);
                    setLocationLoading(false);
                    setShowLocationModal(false); // Close location modal after permission is granted
                    localStorage.setItem("locationGranted", "true");
                    localStorage.setItem("locationBlocked", "false");
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        // User has denied the location request
                        setLocationGranted(false);
                        setLocationBlocked(true);
                        setShowLocationModal(true); // Show modal with instructions
                        localStorage.setItem("locationBlocked", "true");
                        localStorage.setItem("locationGranted", "false");
                        showNotification(
                            "Error",
                            "Location access denied. Please enable it in your browser settings.",
                            "danger"
                        );
                    } else {
                        // Handle other errors (e.g., timeout or unavailable geolocation)
                        showNotification("Error", "Unable to access location.", "danger");
                    }
                    setLocationLoading(false); // End loading
                }
            );
        } else {
            showNotification(
                "Error",
                "Geolocation is not supported by this browser.",
                "danger"
            );
            setLocationLoading(false); // End loading
        }
    };

    useEffect(() => {
        // The condition to call the API when location is granted or blocked
        if (locationGranted || locationBlocked) {
            // Call the API after location permission is granted or blocked
            callApi();
        }
    }, [locationGranted, locationBlocked, latitude, longitude]); // This effect runs when any of these states change

    // Function to initiate the OTP API call after location is determined
    const callApi = () => {
        const { number, type, countryCode, language } = formData;
        const langId = language.langID;
        // Validate number and other necessary fields before making the API call
        setValidating(true);
        // showNotification("Success", "Making api call", "success");
        api
            .get(`/api/employee/validate?number=${number}`, {
                headers: { "Cache-Control": "no-cache" },
            })
            .then((response) => {
                if (response.status === 200) {
                    // No user registered with this number, proceed to the next step
                    api
                        .post("/api/send-otp", {
                            number,
                            type,
                            countryCode,
                            langId,
                            latitude: locationGranted ? latitude : null,
                            longitude: locationGranted ? longitude : null,
                            locationBlocked: locationBlocked ? "blocked" : "enabled", // Pass locationBlocked flag if denied
                        })
                        .then((otpResponse) => {
                            if (otpResponse.status === 200) {
                                setValidating(false);
                                setOtpSent(true);
                                setTimerActive(true);
                                showNotification(
                                    "Success",
                                    "OTP sent successfully!",
                                    "success"
                                );
                                // Trigger any other actions after OTP is sent (e.g., show OTP form)
                            } else {
                                setValidating(false);
                                showNotification("Error", "Failed to send OTP", "danger");
                            }
                        })
                        .catch((otpError) => {
                            setValidating(false);
                            let otpErrorMsg = "An error occurred while sending OTP";
                            if (
                                otpError.response &&
                                otpError.response.data &&
                                otpError.response.data.message
                            ) {
                                otpErrorMsg = otpError.response.data.message;
                            }
                            showNotification("Error", otpErrorMsg, "danger");
                        });
                } else {
                    setValidating(false);
                    showNotification("Error", "Number is already registered", "danger");
                }
            })
            .catch((error) => {
                setValidating(false);
                let errormsg = "Number is already registered";
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    errormsg = error.response.data.message;
                }
                showNotification("Error", errormsg, "danger");
            });
    };

    // Render location modal if needed
    const renderLocationModal = () => {
        return (
            <div style={styles.modal}>
                <div style={styles.modalContent}>
                    {locationBlocked ? null : (
                        <React.Fragment>
                            <h3>We need your location!</h3>
                            <p>
                                To provide better services, we require access to your location.
                                Please click "Allow Location" to grant us permission.
                            </p>
                            <p style={{ color: "#1d5dc9" }}>
                                Note: Please do not click on "Never" when location popup/dialog
                                appears
                            </p>
                            <button
                                onClick={requestLocation}
                                style={styles.modalButton}
                                disabled={isLocationLoading}
                            >
                                {isLocationLoading ? "Please Wait..." : "Allow Location"}
                            </button>
                        </React.Fragment>
                    )}
                    {locationBlocked && (
                        <div
                            style={{
                                textAlign: "left",
                                color: "#ba1d1d",
                                background: "#fff8f8",
                                padding: "2px 2px",
                                borderRadius: "5px",
                            }}
                        >
                            <p style={styles.blockedText}>
                                Location access is required to continue. Please enable it in
                                your browser settings:
                                <br />
                                1. Go to your browser settings.
                                <br />
                                2. Find "Site Settings" or "Permissions".
                                <br />
                                3. Find "Location" within "Permissions" and check the "Blocked"
                                section.
                                <br />
                                4. You will find this site in your blocked section
                                "https://frontend-test-tdt.el.r.appspot.com".
                                <br />
                                5. Click and remove this, and enable location access for this
                                site.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    };
    const convertAssetInfo = (assetInfo) => {
        // Default values for all fields
        const assetData = {
            smartphone: assetInfo.smartphone ? 1 : 0,
            isAndroid: assetInfo.smartphoneOptions.includes("Android") ? 1 : 0,
            iphone: assetInfo.smartphoneOptions.includes("iPhone") ? 1 : 0,
            Symbian: assetInfo.smartphoneOptions.includes("Symbian") ? 1 : 0,
            tablet: assetInfo.tablet ? 1 : 0,
            Android: assetInfo.tabletOptions.includes("Android") ? 1 : 0,
            ios: assetInfo.tabletOptions.includes("iPad") ? 1 : 0, // Assuming iPad means iOS
            windows: assetInfo.tabletOptions.includes("Windows") ? 1 : 0,
            computer: assetInfo.computer ? 1 : 0,
            desktop: assetInfo.computer
                ? assetInfo.computerOptions.includes("Desktop")
                    ? 1
                    : 0
                : 0,
            laptop: assetInfo.computer
                ? assetInfo.computerOptions.includes("Laptop")
                    ? 1
                    : 0
                : 0,
            windowsOS: assetInfo.computerOSOptions.includes("Windows") ? 1 : 0,
            macOS: assetInfo.computerOSOptions.includes("MacOS") ? 1 : 0,
            linux: assetInfo.computerOSOptions.includes("Linux") ? 1 : 0,
            Internet: assetInfo.internet ? 1 : 0,
            BroadBand: assetInfo.internetOptions.includes("Broadband") ? 1 : 0,
            datapack: assetInfo.internetOptions.includes("DataPack") ? 1 : 0,
            vehicle: assetInfo.vehicle ? 1 : 0,
            Cycle: assetInfo.vehicleOptions.includes("Cycle") ? 1 : 0,
            "4-Wheeler": assetInfo.vehicleOptions.includes("4-Wheeler") ? 1 : 0,
            Scooty_Motorcycle: assetInfo.vehicleOptions.includes("Scooty/Motorcycle")
                ? 1
                : 0,
        };

        return assetData;
    };

    const handleSubmit = () => {
        // First, validate the current step
        const validationError = validateCurrentStep();
        if (!validationError) {
            // Only proceed to the submission step if there are no validation errors
            handleFormSubmit();
        }
    };
    const [submitting, setSubmitting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const handleFormSubmit = () => {
        // Prepare the addressInfo object
        setSubmitting(true);
        const addressInfo = {
            countryCode: formData.countryCode,
            latitude: latitude,
            longitude: longitude,
            country: formData.country.countryName,
            level1: formData.level1 ? formData.level1.level1Name : "",
            level2: formData.level2 ? formData.level2.level2Name : "",
            level3: formData.level3 ? formData.level3.level3Name : "",
            level4: formData.level4 ? formData.level4.level4Name : "",
            level5: formData.level5 ? formData.level5.level5Name : "",
            level1Type: formData.level1 ? formData.level1.level1Type : "",
            level2Type: formData.level2 ? formData.level2.level2Type : "",
            level3Type: formData.level3 ? formData.level3.level3Type : "",
            level4Type: formData.level4 ? formData.level4.level4Type : "",
            level5Type: formData.level5 ? formData.level5.level5Type : "",
            level1New: formData.level1New,
            level2New: formData.level2New,
            level3New: formData.level3New,
            level4New: formData.level4New,
            level5New: formData.level5New,
            location: formData.location,
            addressLandmark: formData.addressLandmark,
        };
        const assetInfo = {
            smartphone: formData.smartphone,
            tablet: formData.tablet,
            computer: formData.computer,
            internet: formData.internet,
            vehicle: formData.vehicle,
            smartphoneOptions: formData.smartphoneOptions,
            tabletOptions: formData.tabletOptions,
            computerOptions: formData.computerOptions,
            internetOptions: formData.internetOptions,
            vehicleOptions: formData.vehicleOptions,
            computerOSOptions: formData.computerOSOptions,
        };
        const transformedLanguages = formData.languages.map((lang) => ({
            language: {
                ...lang.language,
                read: lang.read,
                write: lang.write,
                speak: lang.speak,
            },
        }));
        // Group data into required structure
        const groupedData = {
            personalInfo: {
                number: formData.number,
                langID: formData.language.langID,
                gender: formData.gender,
                salutation: formData.salutation,
                firstName: formData.firstName,
                middleName: formData.middleName,
                lastName: formData.lastName,
                dob: formData.dob,
                age: formData.age,
                email: formData.email,
            },
            addressInfo: addressInfo,
            educationInfo: {
                level:
                    formData.educationLevel.level !== "Not-Listed"
                        ? formData.educationLevel.level
                        : formData.educationLevelInput,
                fieldOfStudy:
                    formData.fieldOfStudy.fieldOfStudy !== "Not-Listed"
                        ? formData.fieldOfStudy.fieldOfStudy
                        : formData.fieldOfStudyInput,
                profession:
                    formData.profession.profession !== "Not-Listed"
                        ? formData.profession.profession
                        : formData.professionInput,
            },
            // Convert assetInfo to match the table schema
            assetData: convertAssetInfo(assetInfo),
            languages: transformedLanguages, // Array of language objects with read, write, speak options
        };
        // Send data to the backend
        api
            .post("/api/employee/registration", groupedData)
            .then((response) => {
                console.log("Form submission response:", response.data);
                // Handle success response
                setSubmitting(false);
                showNotification("Success", "Registeration Successful!", "success");
                window.open(`/`, "_self");
            })
            .catch((error) => {
                console.error(
                    "There was an error submitting the form!",
                    error.response.data.message
                );
                showNotification("Error", `${error.response.data.message}`, "danger");
                setSubmitting(false);
                // Handle error response
            });
    };
    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDate.getDate())
        ) {
            age--;
        }
        return age;
    };
    // Handle input changes
    const handleChange = (event) => {
        const { name, value } = event.target;

        // Update state based on the input name
        setFormData((prevData) => {
            let newSalutation = prevData.salutation;
            if (name === "dob") {
                // Calculate age if the date of birth changes
                return {
                    ...prevData,
                    dob: value,
                    age: value ? calculateAge(value) : "", // Calculate age if value is not empty
                };
            } else if (name === "gender") {
                // Update salutation based on gender selection
                if (value === "male") {
                    newSalutation = "Mr"; // Auto-select Mr if only option available
                } else if (value === "female") {
                    newSalutation = ""; // Leave blank since there are multiple options
                } else if (value === "neutral") {
                    newSalutation = "Mx"; // Auto-select Mx if only option available
                }
            } else if (name === "salutation") {
                // Allow the user-selected salutation to override
                newSalutation = value;
            }
            return {
                ...prevData,
                [name]: value,
                salutation: newSalutation, // Update salutation
            };
        });
    };
    const handleCountryChange = (selectedOption) => {
        setFormData({
            ...formData,
            country: selectedOption ? selectedOption : "",
            countryCode: selectedOption ? selectedOption.countryCode : "IND",
            level1: null,
            level2: null,
            level3: null,
            level4: null,
            level5: null,
            level1New: "",
            level2New: "",
            level3New: "",
            level4New: "",
            level5New: "",
            languages: [],
        });
    };
    const handleAppLanguageChange = (selectedOption) => {
        setFormData({
            ...formData,
            language: selectedOption,
        });
    };
    const [step, setStep] = useState(1);
    const [addressLevels, setAddressLevels] = useState(null);
    const totalSteps = 6; // Update this to the total number of steps in your form
    const handleAddressLevels = (data) => {
        setAddressLevels(data);
    };
    // const [validating, setValidating] = useState(false);
    const handleNext = () => {
        const validationError = validateCurrentStep();
        if (step === 1 && !validationError) {
            // showNotification("Success", 'Get otp clicked making api call', "success");
            // Before sending OTP, check location permission
            const locationPermission = localStorage.getItem("locationGranted");
            if (locationPermission !== "true") {
                // showNotification("Success", `locationPermission:${locationPermission}`, "success");
                // console.log('if locationPermission not true')
                setShowLocationModal(true); // Show location modal if permission not granted
            } else {
                // callApi();
                // requestLocation()
                if ((latitude, longitude)) {
                    //  showNotification("Success", `latitude:${latitude,longitude}`, "success");
                    // console.log('if condition')
                    callApi(); // Call the API after the location is successfully captured
                } else {
                    // console.log('else condition')
                    // showNotification(
                    //     "Success",
                    //     `calling requestLocation again`,
                    //     "success"
                    // );
                    requestLocation();
                }
            }
        } else if (step === 3 && !validationError) {
            setValidating(true);
            const { email } = formData;
            api
                .get(`/api/employee/email-validate?email=${email}`, {
                    headers: { "Cache-Control": "no-cache" },
                })
                .then((response) => {
                    if (response.status === 200) {
                        setStep(step + 1);
                    }
                    setValidating(false);
                })
                .catch((error) => {
                    let errormsg = "";
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    ) {
                        errormsg = error.response.data.message;
                    }

                    showNotification("Error", `${errormsg}`, "danger");
                    setValidating(false);
                    // Handle error response
                });
        } else if (!validationError) {
            setStep(step + 1);
        }
    };

    const handlePrev = () => {
        setStep(step - 1);
    };
    // Function to validate email format
    function isValidEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic regex for email validation
        return emailPattern.test(email);
    }
    const validateCurrentStep = () => {
        let errorMessage = "";
        let warningMessage = "";
        if (step === 1) {
            if (!formData.country) {
                errorMessage = "Country is missing.";
            } else if (!formData.language) {
                errorMessage = "Language is missing.";
            } else if (!formData.number) {
                errorMessage = "Number is missing.";
            } else if (
                formData.number &&
                (formData.number.trim().length < 5 ||
                    formData.number.trim().length > 12)
            ) {
                errorMessage = "Number must be a 7 to 12-digit number.";
            }
        } else if (step === 2) {
            if (!formData.gender) {
                errorMessage = "Gender is missing.";
            } else if (!formData.salutation) {
                errorMessage = "Salutation is missing.";
            }
        } else if (step === 3) {
            if (!formData.firstName) {
                errorMessage = "First name is missing.";
            } else if (!formData.lastName) {
                errorMessage = "Last name is missing.";
            } else if (!formData.dob) {
                errorMessage = "Date of birth is missing.";
            } else if (formData.age < 15) {
                errorMessage = "Age must be greater than or equal to 15.";
            } else if (!formData.age) {
                errorMessage = "Age is missing.";
            } else if (!formData.email) {
                errorMessage = "Email is missing.";
            } else if (!isValidEmail(formData.email)) {
                errorMessage = "Email format is invalid.";
            }
        } else if (step === 4) {
            if (!formData.addressLandmark) {
                errorMessage = "Nearest Landmark or Locality is missing";
            }
            if (!formData.location) {
                errorMessage = "Name of your Town/Village is missing";
            }
            if (addressLevels[4] && !formData.level5) {
                errorMessage = `${addressLevels[4]} is missing.`;
            } else if (
                addressLevels[4] &&
                formData.level5.level5Name === "Not-Listed" &&
                !formData.level5New
            ) {
                errorMessage = `Type ${addressLevels[4]}`;
            }
            if (addressLevels[3] && !formData.level4) {
                errorMessage = `${addressLevels[3]} is missing.`;
            } else if (
                addressLevels[3] &&
                formData.level4.level4Name === "Not-Listed" &&
                !formData.level4New
            ) {
                errorMessage = `Type ${addressLevels[3]}`;
            }
            if (addressLevels[2] && !formData.level3) {
                errorMessage = `${addressLevels[2]} is missing.`;
            } else if (
                addressLevels[2] &&
                formData.level3.level3Name === "Not-Listed" &&
                !formData.level3New
            ) {
                errorMessage = `Type ${addressLevels[2]}`;
            }
            if (addressLevels[1] && !formData.level2) {
                errorMessage = `${addressLevels[1]} is missing.`;
            } else if (
                addressLevels[1] &&
                formData.level2.level2Name === "Not-Listed" &&
                !formData.level2New
            ) {
                errorMessage = `Type ${addressLevels[1]}`;
            }
            if (addressLevels[0] && !formData.level1) {
                errorMessage = `${addressLevels[0]} is missing.`;
            } else if (
                addressLevels[0] &&
                formData.level1.level1Name === "Not-Listed" &&
                !formData.level1New
            ) {
                errorMessage = `Type ${addressLevels[0]}`;
            }
        } else if (step === 5) {
            if (!formData.profession) {
                errorMessage = "Profession is missing.";
            } else if (
                formData.profession.profession === "Not-Listed" &&
                !formData.professionInput
            ) {
                errorMessage = "Type Profession.";
            }
            if (!formData.fieldOfStudy) {
                errorMessage = "Field of study is missing.";
            } else if (
                formData.fieldOfStudy.fieldOfStudy === "Not-Listed" &&
                !formData.fieldOfStudyInput
            ) {
                errorMessage = "Type Field of Study.";
            }
            if (!formData.educationLevel) {
                errorMessage = "Education level is missing.";
            } else if (
                formData.educationLevel.level === "Not-Listed" &&
                !formData.educationLevelInput
            ) {
                errorMessage = "Type Education level.";
            }
            if (formData.languages.length === 0) {
                errorMessage = "At least one language must be added.";
            }
            for (const lang of formData.languages) {
                if (!lang.language) {
                    errorMessage = `Language must be added`;
                }
            }
            let hasEnglishOnly =
                formData.languages.length === 1 &&
                formData.languages[0].langID === "eng";
            if (hasEnglishOnly) {
                warningMessage =
                    "Please add your mother tongue or any other languages you know.";
            }
            // Check each language for corresponding checkbox requirements
            for (const lang of formData.languages) {
                if (lang.language && !lang.read && !lang.write && !lang.speak) {
                    errorMessage = `At least one skill (Read, Write, Speak) must be selected for ${lang.language.language}.`;
                }
            }
        } else if (step === 6) {
            // Add checks for asset information
            if (
                !formData.smartphone &&
                !formData.computer &&
                !formData.vehicle &&
                !formData.internet &&
                !formData.tablet
            ) {
                errorMessage = "Asset informatio is missing.";
            }
            // Check smartphone options
            if (formData.smartphone) {
                const smartphoneOptions = [
                    formData.smartphoneOptions.includes("Android"),
                    formData.smartphoneOptions.includes("iPhone"),
                    formData.smartphoneOptions.includes("Symbian"),
                ];
                if (!smartphoneOptions.some((option) => option)) {
                    errorMessage = "At least one smartphone option must be selected.";
                }
            }
            // Check tablet options
            if (formData.tablet) {
                const tabletOptions = [
                    formData.tabletOptions.includes("Android"),
                    formData.tabletOptions.includes("iPad"),
                    formData.tabletOptions.includes("Windows"),
                ];
                if (!tabletOptions.some((option) => option)) {
                    errorMessage = "At least one tablet option must be selected.";
                }
            }
            // Check computer options
            if (formData.computer) {
                const computerOptions = [
                    formData.computerOptions.includes("Windows"),
                    formData.computerOptions.includes("Desktop"),
                    formData.computerOptions.includes("Laptop"),
                ];
                const computerOSOptions = [
                    formData.computerOSOptions.includes("Windows"),
                    formData.computerOSOptions.includes("MacOS"),
                    formData.computerOSOptions.includes("Linux"),
                ];
                if (
                    !computerOptions.some((option) => option) ||
                    !computerOSOptions.some((option) => option)
                ) {
                    errorMessage =
                        "At least one computer option and one OS option must be selected.";
                }
            }
            // Check internet options
            if (formData.internet) {
                const internetOptions = [
                    formData.internetOptions.includes("Broadband"),
                    formData.internetOptions.includes("DataPack"),
                ];
                if (!internetOptions.some((option) => option)) {
                    errorMessage = "At least one internet option must be selected.";
                }
            }
            // Check vehicle options
            if (formData.vehicle) {
                const vehicleOptions = [
                    formData.vehicleOptions.includes("Cycle"),
                    formData.vehicleOptions.includes("4-Wheeler"),
                    formData.vehicleOptions.includes("Scooty/Motorcycle"),
                ];
                if (!vehicleOptions.some((option) => option)) {
                    errorMessage = "At least one vehicle option must be selected.";
                }
            }
        }

        if (errorMessage) {
            showNotification("Error", errorMessage, "danger");
            return true; // Return true if there was an error
        }
        if (warningMessage) {
            console.log("warningMessage", warningMessage);
            showNotification("Warning", warningMessage, "warning");
        }
        return false; // Return false if there were no errors
    };
    const App = (index, key, value) => {
        const updatedLanguages = [...formData.languages];
        // If the key is 'language', use the selected language object
        if (key === "language") {
            updatedLanguages[index] = {
                ...updatedLanguages[index],
                // [key]: value.language,  // Store the language name
                [key]: value, // Store the language name
                langID: value.langID, // Store the langID for reference
                id: value.id, // Store the id if needed
            };
        } else {
            updatedLanguages[index] = {
                ...updatedLanguages[index],
                [key]: value,
            };
        }
        setFormData({ ...formData, languages: updatedLanguages });
    };

    const handleAddLanguage = () => {
        setFormData({
            ...formData,
            languages: [
                ...formData.languages,
                { language: "", read: false, write: false, speak: false },
            ],
        });
    };

    const handleRemoveLanguage = (index) => {
        const updatedLanguages = formData.languages.filter((_, i) => i !== index);
        setFormData({ ...formData, languages: updatedLanguages });
    };
    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() - 15); // Maximum date is 15 years ago

    const minDate = new Date();
    minDate.setFullYear(currentDate.getFullYear() - 100); // Minimum date is 100 years ago

    const maxDateString = maxDate.toISOString().split("T")[0];
    const minDateString = minDate.toISOString().split("T")[0];
    const [otpSent, setOtpSent] = useState(false);
    const [otpTimer, setOtpTimer] = useState(120);
    const [timerActive, setTimerActive] = useState(false);
    const [validating, setValidating] = useState(false);

    const handleVerifyOtp = async (otp) => {
        const { number } = formData;
        if (!otp) {
            showNotification("Error", "OTP is missing", "danger");
            return; // Exit the function early
        }

        // Check if otp length is 6 digits
        if (otp.length !== 6) {
            showNotification("Error", "6-digit OTP required", "danger");
            return; // Exit the function early
        }
        try {
            const response = await api.post("/api/verify-otp", { number, otp });
            if (response.status === 200) {
                showNotification("Success", "OTP verified successfully!", "success");
                setStep(step + 1); // Move to next step
            } else {
                showNotification("Error", "Invalid OTP", "danger");
            }
        } catch (error) {
            let errormsg = error.response?.data?.message || "Error verifying otp";
            showNotification("Error", errormsg, "danger");
        }
    };

    useEffect(() => {
        let timer;
        if (timerActive && otpTimer > 0) {
            timer = setInterval(() => setOtpTimer((prev) => prev - 1), 1000);
        } else if (otpTimer === 0) {
            setTimerActive(false);
            setOtpTimer(120); // Reset timer
        }
        return () => clearInterval(timer);
    }, [timerActive, otpTimer]);
    return (
        <div style={styles.container}>
            {showLocationModal && renderLocationModal()}
            {showLocationModal ? null : (
                <React.Fragment>
                    {/* <Modal
                        customStyles={{
                            content: { width: "90%", maxWidth: "80%" },
                            overlay: { backgroundColor: "#000000f0" },
                        }}
                        title=""
                        onClose={() => setModalOpen(false)}
                        hideClosebtn={true}
                        isOpen={modalOpen}
                    >
                        <div>
                            <LocationModal>
                                <p>
                                    Your registration is complete. Click on "Login" to start
                                    tasks.
                                </p>
                                <button onClick={redirectFunc}>Login</button>
                            </LocationModal>
                        </div>
                    </Modal> */}
                    <LogoName>
                        <img src={Logo} alt="" />
                    </LogoName>
                    {step === 1 ? (
                        <InfoHeading>
                            Welcome! Let’s get started with your registration
                        </InfoHeading>
                    ) : null}
                    {step === 2 ? (
                        <InfoHeading>Information to personalize your tasks</InfoHeading>
                    ) : null}
                    {step === 3 ? (
                        <InfoHeading>
                            Fill in your details to get started with us
                        </InfoHeading>
                    ) : null}
                    {step === 4 ? (
                        <InfoHeading>
                            Location details for relevant task assignments
                        </InfoHeading>
                    ) : null}
                    {step === 5 ? (
                        <InfoHeading>
                            Skill & language for the right task assignments
                        </InfoHeading>
                    ) : null}
                    {step === 6 ? (
                        <InfoHeading>Amenity profile to enhance your earnings</InfoHeading>
                    ) : null}
                    <h2 style={{ marginTop: 0 }}>Employee Registration</h2>
                    <InputContainer>
                        {step === 1 && (
                            <div>
                                <fieldset>
                                    <legend>Basic Information</legend>
                                    <div style={styles.inputContainer}>
                                        <label>Select Country*</label>
                                        <div style={{ marginTop: "4px" }}>
                                            <CountryList
                                                value={formData.country}
                                                onChange={handleCountryChange}
                                                options={{ isClearable: true }}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <label>
                                            Select your preferred App/Browser form language*
                                        </label>
                                        <div style={{ marginTop: "4px" }}>
                                            <AllLanguageList
                                                value={formData.language}
                                                onChange={handleAppLanguageChange}
                                                options={{ isClearable: true }}
                                                countryCode={formData.countryCode}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <label>Enter Mobile Number*</label>
                                        <input
                                            type="tel"
                                            name="number"
                                            placeholder="like 1234567890, do not provide country code"
                                            value={formData.number}
                                            onChange={handleChange}
                                            required={true}
                                            maxLength={12}
                                            disabled={otpSent}
                                        />
                                    </div>
                                    {otpSent ? (
                                        <div style={styles.inputContainer}>
                                            <label>Enter OTP*</label>
                                            <input
                                                type="tel"
                                                name="otp"
                                                placeholder="Enter OTP"
                                                value={formData.otp}
                                                onChange={(e) =>
                                                    setFormData({ ...formData, otp: e.target.value })
                                                }
                                                required={true}
                                                maxLength={6}
                                            />
                                            {timerActive && (
                                                <p
                                                    style={{
                                                        margin: "0",
                                                        padding: "4px 0",
                                                        fontSize: "15px",
                                                        color: "#b92525",
                                                    }}
                                                >
                                                    Resend OTP in {otpTimer} seconds
                                                </p>
                                            )}
                                            {!timerActive && (
                                                <div style={{ marginTop: "5px" }}>
                                                    {" "}
                                                    <NextBtn onClick={handleNext}>Resend OTP</NextBtn>
                                                </div>
                                            )}
                                        </div>
                                    ) : null}
                                </fieldset>
                                <div style={{ textAlign: "right" }}>
                                    {otpSent ? (
                                        <NextBtn onClick={() => handleVerifyOtp(formData.otp)}>
                                            Verify OTP
                                        </NextBtn>
                                    ) : (
                                        <NextBtn onClick={handleNext}>Get OTP</NextBtn>
                                    )}
                                </div>
                                <StepCount>
                                    Step {step}/{totalSteps}
                                </StepCount>
                            </div>
                        )}

                        {step === 2 && (
                            <div>
                                <fieldset>
                                    <legend>Select Gender</legend>
                                    <div style={styles.inputContainer}>
                                        <label>Gender*</label>
                                        <select
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="neutral">Gender Neutral</option>
                                        </select>
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <label>Preferred Title*</label>
                                        <select
                                            name="salutation"
                                            value={formData.salutation}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            {formData.gender === "male" && (
                                                <option value="Mr">Mr</option>
                                            )}
                                            {formData.gender === "female" && (
                                                <>
                                                    <option value="Miss">Ms</option>
                                                    <option value="Mrs">Mrs</option>
                                                </>
                                            )}
                                            {formData.gender === "neutral" && (
                                                <option value="Mx">Mx</option>
                                            )}
                                        </select>
                                    </div>
                                </fieldset>
                                <div style={{ textAlign: "right" }}>
                                    {/* <PreviousBtn onClick={handlePrev}>Previous</PreviousBtn> */}
                                    <NextBtn onClick={handleNext}>Next</NextBtn>
                                </div>
                                <StepCount>
                                    Step {step}/{totalSteps}
                                </StepCount>
                            </div>
                        )}

                        {step === 3 && (
                            <div>
                                <fieldset>
                                    <legend>All Personal Details</legend>
                                    <div style={styles.inputContainer}>
                                        <label>First Name*</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <label>Middle Name (Optional)</label>
                                        <input
                                            type="text"
                                            name="middleName"
                                            value={formData.middleName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <label>Last Name/Surname*</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <label>Date of Birth*</label>
                                        <input
                                            type="date"
                                            name="dob"
                                            value={formData.dob}
                                            onChange={handleChange}
                                            max={maxDateString} // Maximum selectable date (15 years ago)
                                            min={minDateString} // Minimum selectable date (100 years ago)
                                        />
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <label>Age*</label>
                                        <input
                                            type="text"
                                            name="age"
                                            placeholder="State if Date of Birth is not known"
                                            value={formData.age}
                                            onChange={handleChange}
                                            disabled
                                        // disabled={formData.dob}
                                        />
                                    </div>
                                    <div style={styles.inputContainer}>
                                        <label>Email*</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </fieldset>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <PreviousBtn onClick={handlePrev}>Previous</PreviousBtn>
                                    <NextBtn onClick={handleNext}>Next</NextBtn>
                                </div>
                                <StepCount>
                                    Step {step}/{totalSteps}
                                </StepCount>
                            </div>
                        )}
                        {step === 4 && (
                            <div>
                                <fieldset>
                                    <legend>Location Details*</legend>
                                    <AddressForm
                                        formData={formData}
                                        setFormData={setFormData}
                                        countryCode={formData.countryCode}
                                        handleAddressLevels={handleAddressLevels}
                                    />
                                </fieldset>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <PreviousBtn onClick={handlePrev}>Previous</PreviousBtn>
                                    <NextBtn onClick={handleNext}>Next</NextBtn>
                                </div>
                                <StepCount>
                                    Step {step}/{totalSteps}
                                </StepCount>
                            </div>
                        )}
                        {step === 5 && (
                            <div>
                                <fieldset>
                                    <legend>Skill & Language Profile</legend>
                                    <Education formData={formData} setFormData={setFormData} />
                                    <div>
                                        <label>Select language's you know*</label>
                                        {formData.languages.map((lang, index) => (
                                            <div key={index} style={{ marginBottom: "10px" }}>
                                                <AllLanguageList
                                                    countryCode={formData.countryCode}
                                                    value={lang.language}
                                                    onChange={(selectedLanguage) =>
                                                        App(index, "language", selectedLanguage)
                                                    }
                                                    placeholder="Select language"
                                                />

                                                <SkillContainer>
                                                    {lang.language && (
                                                        <LanguageCheckbox>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={lang.read}
                                                                    onChange={(e) =>
                                                                        App(index, "read", e.target.checked)
                                                                    }
                                                                />
                                                                Read
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={lang.write}
                                                                    onChange={(e) =>
                                                                        App(index, "write", e.target.checked)
                                                                    }
                                                                />
                                                                Write
                                                            </label>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={lang.speak}
                                                                    onChange={(e) =>
                                                                        App(index, "speak", e.target.checked)
                                                                    }
                                                                />
                                                                Speak
                                                            </label>
                                                        </LanguageCheckbox>
                                                    )}
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveLanguage(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </SkillContainer>
                                            </div>
                                        ))}
                                        <div style={{ marginTop: "5px" }}>
                                            <AddLangBtn type="button" onClick={handleAddLanguage}>
                                                Add Language
                                            </AddLangBtn>
                                        </div>
                                    </div>
                                </fieldset>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <PreviousBtn onClick={handlePrev}>Previous</PreviousBtn>
                                    <NextBtn onClick={handleNext}>Next</NextBtn>
                                </div>
                                <StepCount>
                                    Step {step}/{totalSteps}
                                </StepCount>
                            </div>
                        )}
                        {step === 6 && (
                            <div>
                                <fieldset>
                                    <legend>
                                        Personal Amenity Details
                                        <p style={{ margin: 0, paddingTop: "6px" }}>
                                            What You Have
                                        </p>
                                    </legend>
                                    <div>
                                        <UserAsset formData={formData} setFormData={setFormData} />
                                    </div>
                                </fieldset>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <PreviousBtn onClick={handlePrev}>Previous</PreviousBtn>
                                    {step === totalSteps ? (
                                        <PreviousBtn onClick={handleSubmit}>Submit</PreviousBtn>
                                    ) : (
                                        <NextBtn onClick={handleNext}>Next</NextBtn>
                                    )}
                                </div>
                                <StepCount>
                                    Step {step}/{totalSteps}
                                </StepCount>
                            </div>
                        )}
                    </InputContainer>
                </React.Fragment>
            )}
            <FullpageLoader
                isOpen={submitting || validating}
                message={validating ? "Validating" : "Submiting details...please wait"}
            />
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        padding: "4px",
        boxSizing: "border-box",
        background: "#e2effd",
    },
    modal: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#000000e0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    },
    modalContent: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center",
        width: "80%",
        maxWidth: "400px",
    },
    modalButton: {
        backgroundColor: "#4CAF50",
        color: "white",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
    },
    inputContainer: {
        marginBottom: "15px",
        marginTop: "4px",
    },
    labelContainer: {
        margin: "20px",
    },
    error: {
        color: "red",
        fontSize: "12px",
    },
};
export default RegistrationPage;
