/**
 * @author Kisan Network || Mera GAon
 * @description This file contains most commonly used resuable functions;
 * @tutorial : How to use? import this file in your page using 'import', correct way: 'import * as util from '../commons/commons.js', then use these functions like 'util.convertTimestamp(159000000)'
 */

import moment from "moment";
import { showNotification } from "../actions/index.actions";

export function convertTimestamp(timestampDate, message = "N/A", dateOnly = false) {
    if (timestampDate != null && timestampDate != undefined && timestampDate != "N/A") {
        if (!isNaN(timestampDate)) {
            // if argument passed is null or empty, return 'no record available'
            if (
                timestampDate == null ||
                timestampDate == "null" ||
                timestampDate == "" ||
                timestampDate == undefined ||
                timestampDate == " " ||
                timestampDate == "N/A"
            ) {
                return message;
            } else {
                if (dateOnly) {
                    return moment
                        .unix(timestampDate / 1000)
                        .format("DD/MM/YYYY hh:mm a")
                        .toLowerCase()
                        .split(" ")[0];
                }
                return moment
                    .unix(timestampDate / 1000)
                    .format("DD/MM/YYYY hh:mm a")
                    .toLowerCase();
            }
        } else {
            if (dateOnly) {
                return moment
                    .unix(timestampDate / 1000)
                    .format("DD/MM/YYYY hh:mm a")
                    .toLowerCase()
                    .split(" ")[0];
            }
            return moment(timestampDate)
                .add(5, "hours")
                .add(30, "m")
                .format("DD/MM/YYYY hh:mm a")
                .toLowerCase();
        }
    } else {
        return message;
    }
}

export function findUnique(array, value) {
    return array.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj[value]).indexOf(obj[value]) === pos;
    });
}

// Remove duplicates objects from array of objects on the basis of key
export function uniqueArray(array, keyName) {
    return array.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj[keyName]).indexOf(obj[keyName]) === pos;
    });
}

export function deepCopy(o) {
    var output, v, key;
    output = Array.isArray(o) ? [] : {};
    for (key in o) {
        v = o[key];
        output[key] = typeof v === "object" ? deepCopy(v) : v;
    }
    return output;
}

// get distance between two points
export function getDistance(x1, y1, x2, y2) {
    var xs = x2 - x1;
    var ys = y2 - y1;
    xs *= xs;
    ys *= ys;
    return Math.sqrt(xs + ys);
}

export function convertRangeToDays(dateFrom, dateTo) {
    var oneDay = 1000 * 60 * 60 * 24;
    var diff = dateTo - dateFrom;
    return Math.round(diff / oneDay);
}


export function convertTimestampMMDDYYY(timestampDate) {
    if (
        timestampDate == null ||
        timestampDate == "null" ||
        timestampDate == "" ||
        timestampDate == undefined ||
        timestampDate == " " ||
        timestampDate == "N/A"
    ) {
        return "N/A";
    } else {
        var convertedDate = moment
            .unix(timestampDate / 1000)
            .format("MMM Do YY hh:mm a")
            .toUpperCase();
        var splitted = convertedDate.split(" ");
        var formatted =
            splitted[0] + " " + splitted[1].toLowerCase() + " " + splitted[2] + " " + splitted[3] + " " + splitted[4];
        return formatted;
    }
}

export function convertTimestampToDateOnly(timestampDate, message = "N/A") {
    if (timestampDate != null && timestampDate != undefined && timestampDate != "N/A") {
        if (!isNaN(timestampDate)) {
            // if argument passed is null or empty, return 'no record available'
            if (
                timestampDate == null ||
                timestampDate == "null" ||
                timestampDate == "" ||
                timestampDate == undefined ||
                timestampDate == " " ||
                timestampDate == "N/A"
            ) {
                return message;
            } else {
                return moment
                    .unix(timestampDate / 1000)
                    .format("DD/MM/YYYY hh:mm a")
                    .toLowerCase()
                    .split(" ")[0];
            }
        } else {
            return moment(timestampDate)
                .add(5, "hours")
                .add(30, "m")
                .format("DD/MM/YYYY hh:mm a")
                .toLowerCase()
                .split(" ")[0];
        }
    } else {
        return message;
    }
}

export function convertTimestampDayDate(timestampDate) {
    // if argument passed is null or empty, return 'no record available'
    if (
        timestampDate == null ||
        timestampDate == "null" ||
        timestampDate == "" ||
        timestampDate == undefined ||
        timestampDate == " " ||
        timestampDate == "N/A"
    ) {
        return "N/A";
    } else {
        var date = moment.unix(timestampDate / 1000).format("LLLL");
        return date.split(" ")[0] + " " + date.split(" ")[1] + " " + date.split(" ")[2] + date.split(" ")[3];
    }
}

export function convertTimestampDayDateWithTime(timestampDate) {
    // if argument passed is null or empty, return 'no record available'

    if (
        timestampDate == null ||
        timestampDate == "null" ||
        timestampDate == "" ||
        timestampDate == undefined ||
        timestampDate == " " ||
        timestampDate == "N/A"
    ) {
        return "N/A";
    } else {
        return moment.unix(timestampDate / 1000).format("llll");
    }
}

export function toTimestamp(dateString) {
    return moment(dateString, "DD/MM/YYYY HH:mm").valueOf();
}

// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
export function isValidDate(dateString) {
    if (dateString.split(" ").length > 1) {
        return moment(dateString.split(" ")[0], "DD/MM/YYYY", true).isValid();
    } else {
        return moment(dateString, "DD/MM/YYYY", true).isValid();
    }
}

export function GetFinancialYear() {
    var d = new Date();
    var n = d.getMonth();
    var year = d
        .getFullYear()
        .toString()
        .substr(2, 2);
    if (n > -1 && n < 3) {
        year = (parseInt(year) - 1).toString() + "-" + parseInt(year).toString();
    } else {
        year = parseInt(year).toString() + "-" + (parseInt(year) + 1).toString();
    }
    return year;
}

export function isNumeric(num) {
    return !isNaN(num);
}

export function verifyURL(url) {
    var isURL = /^(ftp|http|https):\/\/[^ "]+$/;
    if (url != undefined) {
        if (url.match(isURL)) {
            return true;
        }
    }
    return false;
}

export function showDateOnly(dateAndTime) {
    return dateAndTime.split(" ")[0];
}

export function showTimeOnly(dateAndTime) {
    if (dateAndTime == "N/A" || dateAndTime == undefined || dateAndTime == null) return "N/A";
    return dateAndTime.split(" ")[1] + dateAndTime.split(" ")[2];
}

export function getQueryString(key) {
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(
                "^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$",
                "i"
            ),
            "$1"
        )
    );
}

export function getQueryParams() {
    const queryString = window.location.search;
    return queryString
        .slice(1)
        .split("&")
        .reduce((prev, curr) => {
            const [key, value] = curr.split("=");
            prev[key] = value;
            return prev;
        }, {});
}

export function enableScroll() {
    document.body.classList.remove("modal-open");
}

export function disableScroll() {
    document.body.classList.add("modal-open");
}

export function escapeAmpersand(value) {
    return typeof value == "string" ? value.replace(/&/g, "%26") : value;
}

/**
 * @description Method to stringify URL and hashamp for GET API use
 * @param {string} URL to hit
 * @param {object} object which contains key and value to be stringified
 * @example getURL('/fetch/survey/images?',{dateFrom:12/05/2017,crop=potato}), URL may contain predefined parameters (someParam=someValue), question mark is optional.
 * @return returns a string like '/fetch/survey/images?dateFrom=12/05/2017&crop=potato'
 */

export function getURL(url, hashmap) {
    var str = "";
    for (var key in hashmap) {
        if (hashmap[key] != null) {
            str += key + "=" + escapeAmpersand(hashmap[key]) + "&";
        }
    }
    if (url.indexOf("?") == -1 && url.indexOf("=") == -1) {
        str = url + "?" + str.substr(0, str.length - 1);
    } else if (url.indexOf("?") > -1 && url.indexOf("=") == -1) {
        str = url + str.substr(0, str.length - 1);
    } else if (url.indexOf("?") == -1 && url.indexOf("=") > -1) {
        str = url + "?" + "&" + str.substr(0, str.length - 1);
    } else if (url.indexOf("?") > -1 && url.indexOf("=") > -1) {
        str = url + "&" + str.substr(0, str.length - 1);
    } else if (url.indexOf("?") > -1 && url.indexOf("=") > -1 && url.indexOf("&") > -1) {
        str = url + str.substr(0, str.length - 1);
    }
    return str;
}

/**
 * @description Method to change value of a specific parameter in a query string
 * @param {string} url query string which contains the parameter to be altered
 * @param {string} param name of the parameter to be altered
 * @param {string} newValue new value for the parameter name passed
 * @example changeParam('/fetch/survey/images?index=10',index,20)
 * @return returns a string like '/fetch/survey/images?index=20' , the value of index has been changed from 10  to 20
 */

export function changeParam(url, param, newValue) {
    var splittedURL = url.split("?");
    var finalArray = [];
    // if url contains multiple parameters
    if (splittedURL[1].indexOf("&") > -1) {
        var ampSplittedURL = splittedURL[1].split("&");
        for (var i = 0; i < ampSplittedURL.length; i++) {
            if (ampSplittedURL[i].split("=")[0] == param) {
                ampSplittedURL[i] = param + "=" + newValue;
                break;
            }
        }
        return splittedURL[0] + "?" + ampSplittedURL.join("&");
    } else {
        if (splittedURL[1].split("=")[0] == param) {
            splittedURL[1] = param + "=" + newValue;
        }
        return splittedURL[0] + "?" + splittedURL[1];
    }
}

export function vehicleFormat(vehicleNumber) {
    vehicleNumber = vehicleNumber.toLowerCase();
    vehicleNumber = vehicleNumber.replace(/[^a-zA-Z0-9]/g, "");
    if (vehicleNumber.length > 10) {
        return null;
    }
    var tempArray = [];
    if (vehicleNumber != null && vehicleNumber != undefined) {
        tempArray.push(vehicleNumber.substr(0, 2));
        tempArray.push(vehicleNumber.substr(2, 2));
        tempArray.push(vehicleNumber.substr(4, 2));
        tempArray.push(vehicleNumber.substr(6, 4));
        var str = tempArray.join("-");
        return str.toUpperCase();
    } else {
        return null;
    }
}

export var isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export var extractBootstrapColumnCount = (number) => {
    if (number == 1) {
        return "12";
    }
    if (number == 2) {
        return "6";
    }
    if (number == 3) {
        return "4";
    }
    if (number == 4) {
        return "3";
    }
    if (number == 5) {
        return "2";
    }
    if (number == 6) {
        return "2";
    }
    return 12;
};

export function binarySearch(arr, num) {
    var left, right, mid;
    var i, j;
    var found = 0;
    while (arr.length) {}
}

export function convertTimestampToDayOnly(dateAndTime) {
    return moment(dateAndTime).format("dddd");
}

export function random(max, min) {
    return Math.floor(Math.random() * max - min + min);
}

export function randomColor() {
    var R = random(255, 0),
        G = random(255, 0),
        B = random(255, 0);
    return {
        backgroundColor: "RGB(" + R + "," + G + "," + B + ")",
        color: contrastForeground(R, G, B),
    };
}

export function contrastForeground(r, g, b) {
    var check = Math.round((parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000);
    return check > 125 ? "black" : "white";
}

export function isInt(n) {
    return n % 1 === 0;
}

export function castInt(value) {
    if (typeof value == "number" || typeof value == "string") {
        if (isNaN(parseFloat(value))) {
            return null;
        }
        return parseFloat(value);
    }
    return null;
}

export function cprint(message, env) {
    if (env == 1) {
        console.log(message);
    }
    return;
}

export function getMessageTime(createdAt) {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    var _getDay = (val) => {
        let day = parseInt(val);
        if (day == 1) {
            return day + "st";
        } else if (day == 2) {
            return day + "en";
        } else if (day == 3) {
            return day + "rd";
        } else {
            return day + "th";
        }
    };

    var _checkTime = (val) => {
        return +val < 10 ? "0" + val : val;
    };

    if (createdAt) {
        const LAST_MESSAGE_YESTERDAY = "YESTERDAY";
        var _nowDate = new Date();
        var _date = new Date(createdAt);
        if (_nowDate.getDate() - _date.getDate() == 1) {
            return LAST_MESSAGE_YESTERDAY;
        } else if (
            _nowDate.getFullYear() == _date.getFullYear() &&
            _nowDate.getMonth() == _date.getMonth() &&
            _nowDate.getDate() == _date.getDate()
        ) {
            return _checkTime(_date.getHours()) + ":" + _checkTime(_date.getMinutes());
        } else {
            return months[_date.getMonth()] + " " + _getDay(_date.getDate());
        }
    }
    return "";
}

export function _timeString(milliseconds) {
    let obj = {
        d: null,
        h: null,
        m: null,
        s: null,
    };
    obj.d = parseInt(milliseconds / (24 * 60 * 60 * 1000));
    milliseconds -= obj.d * (24 * 60 * 60 * 1000);
    obj.h = parseInt(milliseconds / (60 * 60 * 1000));
    milliseconds -= obj.h * (60 * 60 * 1000);
    obj.m = parseInt(milliseconds / (60 * 1000));
    milliseconds -= obj.m * (60 * 1000);
    obj.s = parseInt(milliseconds / (60 * 1000));
    return obj.d ? `${Math.abs(Math.ceil(obj.d))}d ` : 1 + "d ";
    //return `${obj.d ? `${Math.abs(obj.d)}d ` : ''}${obj.h ? `${Math.abs(obj.h)}h ` : ''}${obj.m ? `${Math.abs(obj.m)}m ` : ''} ${obj.s ? `${Math.abs(obj.s)}s ` : ''}`;
}

export function getInitials(firstName, lastName) {
    if (firstName && lastName) {
        return firstName[0].toUpperCase() + lastName[0].toUpperCase();
    }
}

export function getInitialsOfFullName(fullName) {
    if (fullName && fullName.split(" ").length >= 2) {
        return fullName.split(" ")[0][0].toUpperCase() + fullName.split(" ")[1][0].toUpperCase();
    } else {
        return fullName[0].toUpperCase() + fullName[1].toUpperCase();
    }
}

export function groupBy(xs, key) {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function removeWhitespaces(str) {
    const spaceRegEx = /\s/g;
    return str.replace(spaceRegEx, "");
}

export const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        height: "90%",
        width: "95%",
        backgroundColor: "white",
        border: "none",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 10,
    },
};

/**
 * @author Gulam Hussain
 * @param {AxiosError} err axios error object
 * @param {string} defaultError default error message
 * Return an array of error strings
 */
export function flashErrors(err, defaultError = "Unkown error occurred, please try again") {
    if (err === undefined) {
        return;
    }
    const errors = [];
    try {
        if (!err) {
            errors.push("Server unreachable, try again later");
        }
        if (err && err.errors && Array.isArray(err.errors) && err.errors.length !== 0) {
            err.errors.forEach((err) => errors.push(err.msg || err.detail));
        }
        if (errors.length === 0) {
            errors.push(defaultError);
        }
    } catch (e) {
        errors.push({ msg: defaultError });
    }
    errors.forEach((err) => {
        showNotification("Error", err, "danger");
    });
    return errors;
}
export default function notify(message, type) {
    showNotification(".", message, type);
}

export function getAssignedFeatures() {
    let featureList = [];
    const featureArray = localStorage.getItem("featureArray");
    try {
        featureList = JSON.parse(featureArray);
    } catch (err) {
        console.log(err);
    }
    return featureList;
}

export function isfeatureAssigned(featureName) {
    if (!featureName) {
        throw new Error("featureName is required");
    }
    if (typeof featureName !== "string") {
        throw new Error(`isAssigned expect a string, ${typeof featureName} is provided`);
    }
    let isAccess = getAssignedFeatures().find((feature) => feature === featureName.trim());
    return isAccess ? true : false;
}

export function getTimeDiffBreakup(from, to) {
    const diff = from - to;
    const seconds = Math.round(diff / 1000);
    const minutes = Math.round(diff / (1000 * 60));
    const hours = Math.round(diff / (1000 * 60 * 60));
    const days = Math.round(diff / (1000 * 60 * 60 * 24));
    return {
        seconds,
        minutes,
        hours,
        days,
    };
}

export function getCurrentTimestamp() {
    const timeZone = moment().format("Z");
    if (timeZone === "+05:30") {
        return moment().valueOf();
    }
    return moment()
        .utcOffset("+0530")
        .valueOf();
}

export function convertToIST(timestamp) {
    const timeZone = moment(timestamp).format("Z");
    if (timeZone === "+05:30") {
        return moment(timestamp).valueOf();
    }
    return moment(timestamp)
        .utcOffset("+0530")
        .valueOf();
}

/**
 * @author Awadhesh Kumar (31 May 2021)
 * @description get days b/w two timestamp
 * @params startTimestamp number
 * @params endTimestamp number
 */
export function getDays(startTimestamp, endTimestamp) {
    const startTime = moment(startTimestamp);
    const endTime = moment(endTimestamp);
    const days = startTime.diff(endTime, "days");
    return days;
}

export function emphasisContent(content, maxLength = 100) {
    if (content.length > maxLength) {
        content = content.substr(0, maxLength) + "...";
    }
    return `${content}`;
}
