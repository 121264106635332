import React, { useEffect, useState } from "react";
import { getURL } from "./commons/commons";
import Level1Select from "./elements/dropdowns/Level1Select";
import Level2Select from "./elements/dropdowns/Level2Select";
import Level3Select from "./elements/dropdowns/Level3Select";
import Level4Select from "./elements/dropdowns/Level4Select";
import Level5Select from "./elements/dropdowns/Level5Select";
import api from "./api";
import styled from "styled-components";
const LogoName = styled.div`
  margin-bottom: 8px;
`;
function UserLocation({ formData, setFormData, countryCode, handleAddressLevels }) {
    const {
        level1,
        level2,
        level3,
        level4,
        level5,
        level1New,
        level2New,
        level3New,
        level4New,
        level5New,
        location,
        addressLandmark,
    } = formData;

    // const [countries, setCountries] = useState([]);
    const [addressFormat, setAddressFormat] = useState([]);
    const [levels, setLevels] = useState(null);
    const [showLevels, setShowLevels] = useState({
        level1: false,
        level2: false,
        level3: false,
        level4: false,
        level5: false,
    });

    // Function to merge data based on displayOrder
    const mergeDataByDisplayOrder = (data) => {
        const groupedData = data.reduce((acc, item) => {
            const { displayOrder, level } = item;

            if (!acc[displayOrder]) {
                acc[displayOrder] = { displayOrder, level: level || "" };
            } else {
                if (level) {
                    acc[displayOrder].level = acc[displayOrder].level
                        ? `${acc[displayOrder].level}/${level}`
                        : level;
                }
            }

            return acc;
        }, {});

        return Object.values(groupedData).sort(
            (a, b) => a.displayOrder - b.displayOrder
        );
    };

    useEffect(() => {
        if (countryCode) {
            const fetchAddressFormat = async () => {
                try {
                    const url = getURL(`/api/address-format?countryCode=${countryCode}`);
                    const response = await api.get(url, {
                        headers: { "Cache-Control": "no-cache" },
                    });
                    const mergedData = mergeDataByDisplayOrder(
                        response.data.addressFormats
                    );
                    setAddressFormat(mergedData);

                    // Extract levels from the merged data
                    const levels = mergedData.map((item) => item.level);
                    setLevels(levels);
                    handleAddressLevels(levels)
                    // Set showLevels based on the presence of levels at specific positions
                    setShowLevels({
                        level1: levels[0] ? true : false,
                        level2: levels[1] ? true : false,
                        level3: levels[2] ? true : false,
                        level4: levels[3] ? true : false,
                        level5: levels[4] ? true : false,
                    });
                } catch (error) {
                    console.error("Error fetching address format:", error);
                }
            };

            fetchAddressFormat();
        }
    }, [countryCode, setFormData]);

    return (
        <div>
            {showLevels.level1 && (
                <div>
                     <LogoName>
                            <label>Select {levels[0]}</label>
                            <Level1Select
                                countryCode={countryCode}
                                level1Type={levels[0]}
                                value={level1}
                                onChange={(option) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level1: option,
                                    }))
                                }
                            />
                        </LogoName>
                    {level1 && level1.level1Name === "Not-Listed" && (
                        <LogoName>
                            <label>Enter {levels[0]}</label>
                            <input
                                type="text"
                                value={level1New}
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level1New: e.target.value,
                                    }))
                                }
                                placeholder={`Enter ${levels[0]}`}
                            />
                        </LogoName>
                    )}
                     {/* : (
                        <LogoName>
                            <label>Select {levels[0]}</label>
                            <Level1Select
                                countryCode={countryCode}
                                level1Type={levels[0]}
                                value={level1}
                                onChange={(option) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level1: option,
                                    }))
                                }
                            />
                        </LogoName>
                    )} */}
                </div>
            )}
            {showLevels.level2 && (
                <div>
                    {(level1 && level1.level1Name === "Not-Listed") ||
                        (level2 && level2.level2Name === "Not-Listed") ? (
                        <LogoName>
                            <label>Enter {levels[1]}</label>
                            <input
                                type="text"
                                value={level2New}
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level2New: e.target.value,
                                    }))
                                }
                                placeholder={`Enter ${levels[1]}`}
                            />
                        </LogoName>
                    ) : (
                        <LogoName>
                            <label>Select {levels[1]}</label>
                            <Level2Select
                                countryCode={countryCode}
                                level2Type={levels[1]}
                                value={level2}
                                level1Name={level1 ? level1.level1Name : ""}
                                onChange={(option) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level2: option,
                                    }))
                                }
                            />
                        </LogoName>
                    )}
                </div>
            )}
            {showLevels.level3 && (
                <div>
                    {(level1 && level1.level1Name === "Not-Listed") ||
                        (level2 && level2.level2Name === "Not-Listed") ||
                        (level3 && level3.level3Name === "Not-Listed") ? (
                        <LogoName>
                            <label>Enter {levels[2]}</label>
                            <input
                                type="text"
                                value={level3New}
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level3New: e.target.value,
                                    }))
                                }
                                placeholder={`Enter ${levels[2]}`}
                            />
                        </LogoName>
                    ) : (
                        <LogoName>
                            <label>Select {levels[2]}</label>
                            <Level3Select
                                countryCode={countryCode}
                                level3Type={levels[2]}
                                value={level3}
                                level2Name={level2 ? level2.level2Name : ""}
                                onChange={(option) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level3: option,
                                    }))
                                }
                            />
                        </LogoName>
                    )}
                </div>
            )}
            {showLevels.level4 && (
                <div>
                    {(level1 && level1.level1Name === "Not-Listed") ||
                        (level2 && level2.level2Name === "Not-Listed") ||
                        (level3 && level3.level3Name === "Not-Listed") ||
                        (level4 && level4.level4Name === "Not-Listed") ? (
                        <LogoName>
                            <label>Enter {levels[3]}</label>
                            <input
                                type="text"
                                value={level4New}
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level4New: e.target.value,
                                    }))
                                }
                                placeholder={`Enter ${levels[3]}`}
                            />
                        </LogoName>
                    ) : (
                        <LogoName>
                            <label>Select {levels[3]}</label>
                            <Level4Select
                                countryCode={countryCode}
                                level4Type={levels[3]}
                                level3Name={level3 ? level3.level3Name : ""}
                                value={level4}
                                onChange={(option) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level4: option,
                                    }))
                                }
                            />
                        </LogoName>
                    )}
                </div>
            )}
            {showLevels.level5 && (
                <div>
                    {(level1 && level1.level1Name === "Not-Listed") ||
                        (level2 && level2.level2Name === "Not-Listed") ||
                        (level3 && level3.level3Name === "Not-Listed") ||
                        (level4 && level4.level4Name === "Not-Listed") ||
                        (level5 && level5.level5Name === "Not-Listed") ? (
                        <LogoName>
                            <label>Enter {levels[4]}</label>
                            <input
                                type="text"
                                value={level5New}
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level5New: e.target.value,
                                    }))
                                }
                                placeholder={`Enter ${levels[4]}`}
                            />
                        </LogoName>
                    ) : (
                        <LogoName>
                            <label>Select {levels[4]}</label>
                            <Level5Select
                                countryCode={countryCode}
                                level5Type={levels[4]}
                                level4Name={level4 ? level4.level4Name : ""}
                                value={level5}
                                onChange={(option) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        level5: option,
                                    }))
                                }
                            />
                        </LogoName>
                    )}
                </div>
            )}
            <div style={{marginBottom:"8px"}}>
                <label>Type Name of your Town/Village*</label>
                <input
                    type="text"
                    placeholder="Type Name of your Town/Village"
                    value={location}
                    onChange={(e) =>
                        setFormData((prevData) => ({
                            ...prevData,
                            location: e.target.value,
                        }))
                    }
                />
            </div>
            <div style={{marginBottom:"8px"}}>
                <label>Type Nearest Landmark or Locality*</label>
                <input
                    type="text"
                    value={addressLandmark}
                    onChange={(e) =>
                        setFormData((prevData) => ({
                            ...prevData,
                            addressLandmark: e.target.value,
                        }))
                    }
                    placeholder="Type Nearest Landmark or Locality"
                />
            </div>
        </div>
    );
}
export default UserLocation;
