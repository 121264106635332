import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getURL } from "../../commons/commons";
import PropTypes from "prop-types";
import api from '../../api';
/**
 * @author Suman Kumar. 30 August 2024
 */
function Level1Select(props) {
    const [isLoading, setLoader] = useState(true);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!props.countryCode) {
            if (options.length) {
                setOptions([]);
                setLoader(false);
            }
            return;
        }
        setLoader(true);
        // Define an async function inside the useEffect
        const fetchCountrylists = async () => {
            try {
                const url = getURL(
                    `/api/fetch/level1?countryCode=${props.countryCode}`
                );
                const response = await api.get(url, {
                    headers: { "Cache-Control": "no-cache" },
                });
                const countryList = response.data.addressFormats;
                // Group by level1Name
                const groupedData = countryList.reduce((acc, item) => {
                    if (!acc[item.level1Name]) {
                        acc[item.level1Name] = { ...item, id: item.id }; // Initialize with the first ID
                    }
                    // Only store the first ID, ignore others
                    return acc;
                }, {});

                // Prepare and sort the data
                const mergedData = Object.keys(groupedData).map(level1Name => groupedData[level1Name]);

                const sortedData = mergedData.sort((a, b) =>
                    a.level1Name.localeCompare(b.level1Name)
                );
                // Add "Not-Listed" option
                const notListedId = sortedData.length ? Math.max(...sortedData.map(option => option.id)) + 1 : 1;
                const notListedOption = {
                    id: notListedId,
                    level1Name: "Not-Listed",
                    level1Type:props.level1Type
                };

                // Set options with "Not-Listed"
                setOptions([...sortedData, notListedOption]);
                // setOptions(sortedData);
            } catch (error) {
                console.error("Error fetching country lists:", error);
            } finally {
                setLoader(false);
            }
        };

        fetchCountrylists();
    }, [props.countryCode]);

    return (
        <Select
            options={options}
            isLoading={isLoading}
            onChange={props.onChange}
            value={props.value}
            isClearable
            placeholder={props.placeholder ? props.placeholder : "Select"}
            getOptionLabel={(option) => option.level1Name} // Use the custom label format
            getOptionValue={(option) => option.id} // Use the custom value format
            {...props.options}
        />
    );
}

Level1Select.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.object, // Prop types for additional options if needed
};

export default Level1Select;
