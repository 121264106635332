import React, { useState, useEffect } from "react";
import styled from "styled-components";
const LogoName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  input {
    width: 4% !important;
    height: 18px;
  }
  strong {
    font-size: 16px;
  }
  label {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
const AssetContainer = styled.div`
  border-bottom: 1px solid #a1c4ff;
  width: 100%;
  margin-bottom: 8px;
`;
const SubMenu = styled.div`
  margin: 4px 4px 4px 16px;
  font-size: 16px;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: start;
  label {
    display: flex;
    align-items: center;
    gap: 4px;
    input {
      width: auto;
    }
  }
`;
function UserOwnershipResource1({ formData, setFormData }) {
  const [selectedOptions, setSelectedOptions] = useState({
    smartphone: formData.smartphone || false,
    tablet: formData.tablet || false,
    computer: formData.computer || false,
    internet: formData.internet || false,
    vehicle: formData.vehicle || false,
  });

  const [subOptions, setSubOptions] = useState({
    smartphoneOptions: formData.smartphoneOptions || [],
    tabletOptions: formData.tabletOptions || [],
    computerOptions: formData.computerOptions || [],
    internetOptions: formData.internetOptions || [],
    vehicleOptions: formData.vehicleOptions || [],
    computerOSOptions: formData.computerOSOptions || [],
  });

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      ...selectedOptions,
      ...subOptions,
    }));
  }, [selectedOptions, subOptions]);

  const handleMainOptionChange = (event) => {
    const { name, checked } = event.target;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    if (!checked) {
      // Reset sub-options when unchecking
      setSubOptions((prevState) => ({
        ...prevState,
        [`${name}Options`]: [],
        ...(name === "computer" && { computerOSOptions: [] }), // Reset computerOSOptions when computer is unchecked
      }));
    }
  };

  const handleSubOptionChange = (event) => {
    const { name, value, checked } = event.target;
    setSubOptions((prevState) => {
      const optionsKey = `${name}Options`;
      const currentOptions = Array.isArray(prevState[optionsKey])
        ? prevState[optionsKey]
        : [];
      const updatedOptions = checked
        ? [...currentOptions, value]
        : currentOptions.filter((option) => option !== value);

      return {
        ...prevState,
        [optionsKey]: updatedOptions,
      };
    });
  };
  return (
    <div>
      <AssetContainer>
        <LogoName>
          <strong>Smartphone</strong>
          <input
            type="checkbox"
            name="smartphone"
            checked={selectedOptions.smartphone}
            onChange={handleMainOptionChange}
          />
        </LogoName>
        {selectedOptions.smartphone && (
          <SubMenu>
            <label>
              Android
              <input
                type="checkbox"
                name="smartphone"
                value="Android"
                checked={subOptions.smartphoneOptions.includes("Android")}
                onChange={handleSubOptionChange}
              />
            </label>
            <label>
              iPhone
              <input
                type="checkbox"
                name="smartphone"
                value="iPhone"
                checked={subOptions.smartphoneOptions.includes("iPhone")}
                onChange={handleSubOptionChange}
              />
            </label>
            <label>
              Symbian
              <input
                type="checkbox"
                name="smartphone"
                value="Symbian"
                checked={subOptions.smartphoneOptions.includes("Symbian")}
                onChange={handleSubOptionChange}
              />
            </label>
          </SubMenu>
        )}
      </AssetContainer>

      <AssetContainer>
        <LogoName>
          <strong>Tablet</strong>
          <input
            type="checkbox"
            name="tablet"
            checked={selectedOptions.tablet}
            onChange={handleMainOptionChange}
          />
        </LogoName>
        {selectedOptions.tablet && (
          <SubMenu>
            <label>Android
              <input
                type="checkbox"
                name="tablet"
                value="Android"
                checked={subOptions.tabletOptions.includes("Android")}
                onChange={handleSubOptionChange}
              />
            </label>
            <label> iPad
              <input
                type="checkbox"
                name="tablet"
                value="iPad"
                checked={subOptions.tabletOptions.includes("iPad")}
                onChange={handleSubOptionChange}
              />
            </label>
            <label> Windows
              <input
                type="checkbox"
                name="tablet"
                value="Windows"
                checked={subOptions.tabletOptions.includes("Windows")}
                onChange={handleSubOptionChange}
              />
            </label>
          </SubMenu>
        )}
      </AssetContainer>

      <AssetContainer>
        <LogoName>
          <strong>Computer</strong>
          <input
            type="checkbox"
            name="computer"
            checked={selectedOptions.computer}
            onChange={handleMainOptionChange}
          />
        </LogoName>
        {selectedOptions.computer && (
          <SubMenu>
            <label> Desktop
              <input
                type="checkbox"
                name="computer"
                value="Desktop"
                checked={subOptions.computerOptions.includes("Desktop")}
                onChange={handleSubOptionChange}
              />
            </label>
            <label>Laptop
              <input
                type="checkbox"
                name="computer"
                value="Laptop"
                checked={subOptions.computerOptions.includes("Laptop")}
                onChange={handleSubOptionChange}
              />
            </label>
            {(subOptions.computerOptions.includes("Desktop") ||
              subOptions.computerOptions.includes("Laptop")) && (
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="computerOS"
                      value="Windows"
                      checked={subOptions.computerOSOptions.includes("Windows")}
                      onChange={handleSubOptionChange}
                    />
                    Windows
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="computerOS"
                      value="MacOS"
                      checked={subOptions.computerOSOptions.includes("MacOS")}
                      onChange={handleSubOptionChange}
                    />
                    MacOS
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="computerOS"
                      value="Linux"
                      checked={subOptions.computerOSOptions.includes("Linux")}
                      onChange={handleSubOptionChange}
                    />
                    Linux
                  </label>
                </div>
              )}
          </SubMenu>
        )}
      </AssetContainer>

      <AssetContainer>
        <LogoName>
          <strong>Internet</strong>
          <input
            type="checkbox"
            name="internet"
            checked={selectedOptions.internet}
            onChange={handleMainOptionChange}
          />
        </LogoName>
        {selectedOptions.internet && (
          <SubMenu>
            <label>  Data Pack
              <input
                type="checkbox"
                name="internet"
                value="DataPack"
                checked={subOptions.internetOptions.includes("DataPack")}
                onChange={handleSubOptionChange}
              />
            </label>
            <label> Broadband
              <input
                type="checkbox"
                name="internet"
                value="Broadband"
                checked={subOptions.internetOptions.includes("Broadband")}
                onChange={handleSubOptionChange}
              />
            </label>
          </SubMenu>
        )}
      </AssetContainer>

      <AssetContainer>
        <LogoName>
          <strong>Vehicle</strong>
          <input
            type="checkbox"
            name="vehicle"
            checked={selectedOptions.vehicle}
            onChange={handleMainOptionChange}
          />
        </LogoName>
        {selectedOptions.vehicle && (
          <SubMenu>
            <label> Cycle
              <input
                type="checkbox"
                name="vehicle"
                value="Cycle"
                checked={subOptions.vehicleOptions.includes("Cycle")}
                onChange={handleSubOptionChange}
              />
            </label>
            <label>  Scooty/Motorcycle
              <input
                type="checkbox"
                name="vehicle"
                value="Scooty/Motorcycle"
                checked={subOptions.vehicleOptions.includes(
                  "Scooty/Motorcycle"
                )}
                onChange={handleSubOptionChange}
              />
            </label>
            <label>  4-Wheeler
              <input
                type="checkbox"
                name="vehicle"
                value="4-Wheeler"
                checked={subOptions.vehicleOptions.includes("4-Wheeler")}
                onChange={handleSubOptionChange}
              />
            </label>
          </SubMenu>
        )}
      </AssetContainer>
    </div>
  );
}
export default UserOwnershipResource1;