import React from 'react';
import EducationLevelList from "./elements/dropdowns/EducationLevelList";
import FieldOfStudyList from "./elements/dropdowns/FieldOfStudyList";
import ProfessionList from "./elements/dropdowns/ProfessionList";
import styled from "styled-components";
const LogoName = styled.div`
    margin-bottom: 8px;
`;
export default function Education({ formData, setFormData }) {

  // Handler for Education Level changes
  const handleEducationLevelChange = (selectedOption) => {
    if (selectedOption && selectedOption.level === 'Not-Listed') {
      setFormData((prevData) => ({
        ...prevData,
        educationLevel: selectedOption,
        educationLevelInput: '', // Reset input field in formData
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        educationLevel: selectedOption,
        educationLevelInput: null, // Hide input field in formData
      }));
    }
  };

  // Handler for Field of Study changes
  const handleFieldOfStudyChange = (selectedOption) => {
    if (selectedOption && selectedOption.fieldOfStudy === 'Not-Listed') {
      setFormData((prevData) => ({
        ...prevData,
        fieldOfStudy: selectedOption,
        fieldOfStudyInput: '', // Reset input field in formData
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        fieldOfStudy: selectedOption,
        fieldOfStudyInput: null, // Hide input field in formData
      }));
    }
  };

  // Handler for Profession changes
  const handleProfessionChange = (selectedOption) => {
    if (selectedOption && selectedOption.profession === 'Not-Listed') {
      setFormData((prevData) => ({
        ...prevData,
        profession: selectedOption,
        professionInput: '', // Reset input field in formData
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        profession: selectedOption,
        professionInput: null, // Hide input field in formData
      }));
    }
  };

  return (
    <div>
      <LogoName>
        <label>Select Education Level*</label>
        <EducationLevelList
          onChange={handleEducationLevelChange}
          value={formData.educationLevel}
        />
        {formData.educationLevel && formData.educationLevel.level === 'Not-Listed' && (
          <input
            type="text"
            placeholder="Specify Education Level"
            value={formData.educationLevelInput}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                educationLevelInput: e.target.value,
              }))
            }
          />
        )}
      </LogoName>

      <LogoName>
        <label>Select Field Of Study*</label>
        <FieldOfStudyList
          onChange={handleFieldOfStudyChange}
          value={formData.fieldOfStudy}
          highestLevel={formData.educationLevel}
        />
        {formData.fieldOfStudy && formData.fieldOfStudy.fieldOfStudy === 'Not-Listed' && (
          <input
            type="text"
            placeholder="Specify Field of Study"
            value={formData.fieldOfStudyInput}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                fieldOfStudyInput: e.target.value,
              }))
            }
          />
        )}
      </LogoName>

      <LogoName>
        <label>Select Profession*</label>
        <ProfessionList
          onChange={handleProfessionChange}
          value={formData.profession}
        />
        {formData.profession && formData.profession.profession === 'Not-Listed' && (
          <input
            type="text"
            placeholder="Specify Profession"
            value={formData.professionInput}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                professionInput: e.target.value,
              }))
            }
          />
        )}
      </LogoName>
    </div>
  );
}
