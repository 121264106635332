import surveyTypes from "../../actions/Survey/types";

export function surveyQuestionDataList(state = [], action) {
    switch (action.type) {
        case surveyTypes.FETCH_SURVEY_QUESTION_DATA: {
            return [...action.payload];
        }
        case surveyTypes.UPDATE_SURVEY_QUESTION_DATA: {
            const { voiceTaskId, taskUrl } = action.payload;
            const newState = [...state];
            //return newState.filter((survey) => survey.VoiceTasksID !== VoiceTasksID);
            const newData = newState.find((survey) => survey.VoiceTasksID == voiceTaskId);
            if (newData) {
                newData.taskUrl = taskUrl;
            }
            return newState;
        }
        default:
            return state;
    }
}
