import React from "react";
import Modal from "react-modal";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText = "Confirm",
  cancelText = "Cancel",
  isLoading = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="confirmation-modal"
      overlayClassName="confirmation-modal-overlay"
      ariaHideApp={false}
    >
      <div className="confirmation-modal-content">
        <h3 className="confirmation-modal-title">{title}</h3>
        <p className="confirmation-modal-message">{message}</p>

        <div className="confirmation-modal-buttons">
          <button
            onClick={onClose}
            disabled={isLoading}
            className="confirmation-modal-button cancel-button"
          >
            {cancelText}
          </button>
          <button
            onClick={onConfirm}
            disabled={isLoading}
            className="confirmation-modal-button confirm-button"
          >
            {isLoading ? "Processing..." : confirmText}
          </button>
        </div>
      </div>

      <style jsx>{`
        .confirmation-modal {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: white;
          border-radius: 8px;
          padding: 0;
          max-width: 500px;
          width: 90%;
          outline: none;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .confirmation-modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .confirmation-modal-content {
          padding: 24px;
        }

        .confirmation-modal-title {
          font-size: 20px;
          font-weight: 600;
          margin: 0 0 16px 0;
          color: #1a1a1a;
        }

        .confirmation-modal-message {
          font-size: 16px;
          line-height: 1.5;
          margin: 0 0 24px 0;
          color: #4a5568;
        }

        .confirmation-modal-buttons {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
        }

        .confirmation-modal-button {
          padding: 8px 16px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          border: none;
          transition: all 0.2s ease;
        }

        .confirmation-modal-button:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }

        .cancel-button {
          background-color: #e2e8f0;
          color: #4a5568;
        }

        .cancel-button:hover:not(:disabled) {
          background-color: #cbd5e0;
        }

        .confirm-button {
          background-color: #3182ce;
          color: white;
        }

        .confirm-button:hover:not(:disabled) {
          background-color: #2c5282;
        }
      `}</style>
    </Modal>
  );
};

export default ConfirmationModal;
