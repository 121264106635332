import React, { useState, useEffect } from "react";
import { showNotification } from "../../actions/index.actions";
import api from "../../api";

const CommentsSection = ({
  selectedComment,
  setSelectedComment,
  additionalComment,
  setAdditionalComment,
  t,
}) => {
  const [commentOptions, setCommentOptions] = useState([]);
  const maxCharLimit = 300;

  useEffect(() => {
    const fetchCommentOptions = async () => {
      try {
        const response = await api.get("/api/employee/comment-options", {
          params: { contentType: "audio" },
          headers: { "Cache-Control": "no-cache" },
        });

        if (response.data) {
          setCommentOptions(response.data);
        } else {
          throw new Error("No comment options received");
        }
      } catch (error) {
        showNotification(
          "Error",
          "Failed to load comment options. Please refresh the page.",
          "danger"
        );
      }
    };

    fetchCommentOptions();
  }, []);

  const handleAdditionalCommentChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxCharLimit) {
      setAdditionalComment(text);
    } else {
      showNotification(
        "Warning",
        `Comments are limited to ${maxCharLimit} characters`,
        "warning"
      );
    }
  };

  return (
    <div style={styles.commentsContainer}>
      <div style={styles.comments}>
        <h2 style={styles.heading}>{t("comments")}</h2>
        <div style={styles.commentInputs}>
          <select
            style={styles.select}
            value={selectedComment}
            onChange={(e) => setSelectedComment(e.target.value)}
          >
            <option value="" style={styles.optionHeading}>
              {t("selectComment")}
            </option>
            {commentOptions.map((option) => (
              <option key={option.OptionID} value={option.OptionID}>
                {option.OptionText}
              </option>
            ))}
          </select>
          <textarea
            style={styles.textarea}
            value={additionalComment}
            onChange={handleAdditionalCommentChange}
            placeholder={t("typeAdditionalComments")}
            maxLength={maxCharLimit}
          />
          <div style={styles.charCount}>
            {additionalComment.length}/{maxCharLimit}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  commentsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  comments: {
    width: "45%",
    maxWidth: "500px",
  },
  commentInputs: {
    display: "flex",
    flexDirection: "column",
    gap: "0.15rem",
  },
  select: {
    padding: "0.25rem",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    fontSize: "0.875rem",
    width: "100%",
  },
  textarea: {
    padding: "0.15rem",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    fontSize: "0.875rem",
    minHeight: "4rem",
    resize: "vertical",
    width: "100%",
  },
  heading: {
    fontSize: "1.25rem",
    fontWeight: 600,
    marginBottom: "0.15rem",
  },
  optionHeading: {
    fontWeight: "bold",
  },
  charCount: {
    fontSize: "0.75rem",
    color: "#718096",
    textAlign: "right",
  },
};

export default CommentsSection;
