import React from "react";
import "./EmployeeDashboardStyles.css";

const EmployeeDashboard = () => {
  const userName = localStorage.getItem("userName");

  return <h1 className="welcome-heading">Welcome {userName}</h1>;
};

export default EmployeeDashboard;
