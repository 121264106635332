import React from "react";

const TranscriptSection = ({ transcript, setTranscript, t }) => {
  const maxCharLimit = 3000;

  const handleTranscriptChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxCharLimit) {
      setTranscript(text);
    }
  };

  return (
    <div style={styles.transcript}>
      <h2 style={styles.heading}>{t("transcript")}</h2>
      <p style={styles.transcriptDescription}>{t("writeTranscription")}</p>
      <textarea
        style={styles.transcriptBox}
        value={transcript}
        onChange={handleTranscriptChange}
        placeholder={t("enterTranscript")}
        maxLength={maxCharLimit}
      />
      <div style={styles.charCount}>
        {transcript.length}/{maxCharLimit}
      </div>
    </div>
  );
};

const styles = {
  transcript: {
    width: "98%",
  },
  heading: {
    fontSize: "1.25rem",
    fontWeight: 600,
    marginBottom: "0.15rem",
  },
  transcriptDescription: {
    fontSize: "0.875rem",
    marginBottom: "0.15rem",
  },
  transcriptBox: {
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    padding: "0.5rem",
    fontSize: "0.875rem",
    width: "100%",
    height: "calc(100% - 5rem)", // Adjusted to accommodate character count
    resize: "none",
    overflowY: "auto",
  },
  charCount: {
    fontSize: "0.75rem",
    color: "#718096",
    textAlign: "right",
    marginTop: "0.25rem",
  },
};

export default TranscriptSection;
