import axios from "axios";

const api = axios.create({
  // baseURL: process.env.REACT_APP_API_URL || 'https://second-caster-430015-j9.el.r.appspot.com', // Use for test or development
  baseURL: "https://backend-prod-tdt.el.r.appspot.com",
  // baseURL: "http://localhost:3307",
  headers: {
    "api-key": process.env.REACT_APP_API_KEY,
  },
});

export default api;
