import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SidebarStyles.css";
import allMenus from "./menu"; // Import predefined menus

const Sidebar = ({ isOpen, closeSidebar }) => {
  const navigate = useNavigate();
  const [openMenuIds, setOpenMenuIds] = useState(new Set());
  const [filteredMenus, setFilteredMenus] = useState([]); // To store allowed menus

  useEffect(() => {
    // Fetch allowed menus from localStorage
    const menuAccess = JSON.parse(localStorage.getItem("menuAccess") || "[]");

    // Filter menus by matching IDs from localStorage with subMenu ids
    const allowedMenus = allMenus.map((menu) => {
      const accessibleSubMenus = menu.subMenus.filter((subMenu) =>
        menuAccess.some((access) => access.MenuName === subMenu.id)
      );

      // Only include menus that have accessible submenus
      if (accessibleSubMenus.length > 0) {
        return {
          ...menu,
          subMenus: accessibleSubMenus, // Only include accessible submenus
        };
      }
      return null;
    }).filter((menu) => menu !== null); // Remove null values

    setFilteredMenus(allowedMenus);
  }, []);

  const toggleMenu = (menuId) => {
    setOpenMenuIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(menuId)) {
        newSet.delete(menuId);
      } else {
        newSet.add(menuId);
      }
      return newSet;
    });
  };

  const handleNavigate = (path) => {
    navigate(path);
    closeSidebar();
  };

  const renderMenuItem = (menuItem) => {
    return (
      <div key={menuItem.id} className="menu-item">
        <div className="menu-header" onClick={() => toggleMenu(menuItem.id)}>
          <span>{menuItem.label}</span>
          <span
            className={`arrow ${openMenuIds.has(menuItem.id) ? "down" : ""}`}
          >
            ▸
          </span>
        </div>
        <div
          className={`submenu ${openMenuIds.has(menuItem.id) ? "open" : ""}`}
        >
          {menuItem.subMenus.map((subMenu) => (
            <div
              key={subMenu.id}
              className="submenu-item"
              onClick={() => handleNavigate(subMenu.path)}
              style={{ cursor: "pointer" }}
            >
              {subMenu.label}
            </div>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-menu">
        {filteredMenus.map((menuItem) => renderMenuItem(menuItem))}
      </div>
    </div>
  );
};

export default Sidebar;
