import React, { useState, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { getURL } from "../../commons/commons";
import api from '../../api';
function Level4Select(props) {
    const [isLoading, setLoader] = useState(true);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!props.level3Name || props.level3Name === 'Not-Listed') {
            setOptions([]);
            setLoader(false);
            return;
        }
        setLoader(true);

        const fetchLevel4 = async () => {
            try {
                const url = getURL(`/api/fetch/level4?countryCode=${props.countryCode}&level3Name=${props.level3Name}`);
                const response = await api.get(url, {
                    headers: { "Cache-Control": "no-cache" },
                });
                const countryList = response.data.addressFormats;
                // Group by level4Name
                const groupedData = countryList.reduce((acc, item) => {
                    if (!acc[item.level4Name]) {
                        acc[item.level4Name] = { ...item, id: item.id }; // Initialize with the first ID
                    }
                    // Only store the first ID, ignore others
                    return acc;
                }, {});

                // Prepare and sort the data
                const mergedData = Object.keys(groupedData).map(level4Name => groupedData[level4Name]);

                const sortedData = mergedData.sort((a, b) =>
                    a.level4Name.localeCompare(b.level4Name)
                );
                // Add "Not-Listed" option
                const notListedId = sortedData.length ? Math.max(...sortedData.map(option => option.id)) + 1 : 1;
                const notListedOption = {
                    id: notListedId,
                    level4Name: "Not-Listed",
                    level4Type:props.level4Type
                };

                // Set options with "Not-Listed"
                setOptions([...sortedData, notListedOption]);
                // setOptions(sortedData);
            } catch (error) {
                console.error("Error fetching level 4 data:", error);
            } finally {
                setLoader(false);
            }
        };

        fetchLevel4();
    }, [props.countryCode, props.level3Name]);
    return (
        <Select
            options={options}
            isLoading={isLoading}
            onChange={props.onChange}
            value={props.value}
            isClearable
            placeholder="Select Level 4"
            getOptionLabel={(option) => option.level4Name}
            getOptionValue={(option) => option.level4Name}
        />
    );
}

Level4Select.propTypes = {
    countryCode: PropTypes.string.isRequired,
    level3Name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default Level4Select;
