import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import EmpRegistrationPage from "./Emp_Registration";
import EmpRegistration_Page from "./Registration_Page";
import EmployeeLogin from "./components/EmployeeComponents/EmployeeLogin";
import EmployeeDashboard from "./components/EmployeeComponents/EmployeeDashboard";
import TaskerDashboardPage from "./components/EmployeeComponents/TaskerDashboardPage";
import ProtectedRoute from "./components/ProtectedRoutes";
import TranslationDashboard from "./components/EmployeeComponents/TranslationTaskDashboard";
import AdminReviewDashboard from "./components/EmployeeComponents/EmployeeReReviewDashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/sign-in" element={<EmployeeLogin />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <EmployeeDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee-ratings"
          element={
            <ProtectedRoute>
              <TaskerDashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-employee"
          element={
            <ProtectedRoute>
              <EmpRegistrationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee/registration"
          element={
            <ProtectedRoute>
              <EmpRegistration_Page />
            </ProtectedRoute>
          }
        />
        <Route
          path="/translation-dashboard"
          element={
            <ProtectedRoute>
              <TranslationDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rereview-dashboard"
          element={
            <ProtectedRoute>
              <AdminReviewDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}
export default App;
