import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../EmployeeComponents/Navbar";
import Sidebar from "../EmployeeComponents/Sidebar";

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    marginTop: "50px",
    position: "relative",
    flex: 1,
  },
  overlay: {
    position: "fixed",
    top: "50px",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    opacity: "0",
    visibility: "hidden",
    transition: "opacity 0.3s ease, visibility 0.3s ease",
    zIndex: "999",
  },
  overlayActive: {
    opacity: "1",
    visibility: "visible",
  },
  mainArea: {
    margin: 0,
    padding: 0,
    border: "none",
  },
};

const AuthLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div style={styles.container}>
      <Navbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <div style={styles.mainContent}>
        <div
          style={{
            ...styles.overlay,
            ...(isSidebarOpen ? styles.overlayActive : {}),
          }}
          onClick={toggleSidebar}
        />
        <Sidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
        <div style={styles.mainArea}>{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
