import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getURL } from "../../commons/commons";
import PropTypes from "prop-types";
import api from '../../api';
/**
 * @author Suman Kumar. 30 August 2024
 */
function Level2Select(props) {
    const [isLoading, setLoader] = useState(false);
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (!props.countryCode || !props.level1Name || props.level1Name === 'Not-Listed') {
            return;
        }
        // Define an async function inside the useEffect
        const fetchCountrylists = async () => {
            setLoader(true);
            try {
                const url = getURL(
                    `/api/fetch/level2?countryCode=${props.countryCode}&level1Name=${encodeURIComponent(props.level1Name)}`
                );
                const response = await api.get(url, {
                    headers: { "Cache-Control": "no-cache" },
                });
                const countryList = response.data.addressFormats;
                // Group by level2Name
                const groupedData = countryList.reduce((acc, item) => {
                    if (!acc[item.level2Name]) {
                        acc[item.level2Name] = { ...item, id: item.id }; // Initialize with the first ID
                    }
                    // Only store the first ID, ignore others
                    return acc;
                }, {});

                // Prepare and sort the data
                const mergedData = Object.keys(groupedData).map(level2Name => groupedData[level2Name]);

                const sortedData = mergedData.sort((a, b) =>
                    a.level2Name.localeCompare(b.level2Name)
                );
                // Add "Not-Listed" option
                const notListedId = sortedData.length ? Math.max(...sortedData.map(option => option.id)) + 1 : 1;
                const notListedOption = {
                    id: notListedId,
                    level2Name: "Not-Listed",
                    level2Type:props.level2Type
                };

                // Set options with "Not-Listed"
                setOptions([...sortedData, notListedOption]);
                // setOptions(sortedData);
            } catch (error) {
                console.error("Error fetching country lists:", error);
            } finally {
                setLoader(false);
            }
        };

        fetchCountrylists();
    }, [props.countryCode, props.level1Name]);
    return (
        <Select
            options={options}
            isLoading={isLoading}
            onChange={props.onChange}
            value={props.value}
            isClearable
            placeholder={props.placeholder ? props.placeholder : "Select"}
            getOptionLabel={(option) => option.level2Name} // Use the custom label format
            getOptionValue={(option) => option.id} // Use the custom value format
            {...props.options}
        />
    );
}

Level2Select.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.object, // Prop types for additional options if needed
};

export default Level2Select;
