import React from "react";

import "./styles.css";

const LoadingState = () => (
  <div className="state-wrapper">
    <div className="state-content">
      <h2 className="state-title">Loading...</h2>
      <p className="state-message">
        Please wait while we fetch your dashboard data.
      </p>
    </div>
  </div>
);

export default LoadingState;
