import React, { useState } from "react";
import Modal from "react-modal";

const PostponeModal = ({ isOpen, onClose, onSubmit }) => {
  const [days, setDays] = useState("1");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    const daysNumber = parseInt(days, 10);
    if (isNaN(daysNumber) || daysNumber < 1 || daysNumber > 10) {
      setError("Please enter a valid number between 1 and 10");
      return;
    }
    onSubmit(daysNumber);
    setDays("1");
    setError("");
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        },
        content: {
          position: "relative",
          background: "white",
          borderRadius: "8px",
          width: "400px",
          margin: "auto",
          padding: 0,
          border: "none",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          inset: "unset", // This removes the default Modal positioning
        },
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            padding: "16px 20px",
            borderBottom: "1px solid #e2e8f0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0, fontSize: "1.25rem", fontWeight: 600 }}>
            Postpone Task
          </h2>
          <button
            onClick={onClose}
            style={{
              background: "none",
              border: "none",
              fontSize: "1.5rem",
              cursor: "pointer",
              padding: "0 4px",
              color: "#4A5568",
              lineHeight: 1,
            }}
          >
            ×
          </button>
        </div>

        <div style={{ padding: "20px" }}>
          <div style={{ marginBottom: "16px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: 500,
                color: "#2D3748",
              }}
            >
              Enter number of days to postpone (1-10):
            </label>
            <input
              type="number"
              value={days}
              onChange={(e) => {
                setDays(e.target.value);
                setError("");
              }}
              min="1"
              max="10"
              style={{
                width: "100%",
                padding: "8px 12px",
                border: "1px solid #e2e8f0",
                borderRadius: "4px",
                fontSize: "1rem",
                boxSizing: "border-box",
              }}
            />
            {error && (
              <div
                style={{
                  color: "#e53e3e",
                  fontSize: "0.875rem",
                  marginTop: "4px",
                }}
              >
                {error}
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            padding: "16px 20px",
            borderTop: "1px solid #e2e8f0",
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            backgroundColor: "#F7FAFC",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <button
            onClick={onClose}
            style={{
              padding: "8px 16px",
              border: "1px solid #e2e8f0",
              borderRadius: "4px",
              backgroundColor: "white",
              cursor: "pointer",
              fontSize: "0.875rem",
              color: "#4A5568",
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            style={{
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: "#3182ce",
              color: "white",
              cursor: "pointer",
              fontSize: "0.875rem",
              fontWeight: 500,
            }}
          >
            Postpone
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PostponeModal;
