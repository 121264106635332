import { Store } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';

export const showNotification = (title, message, type, container="top-left") => {
   Store.addNotification({
    title:title,
    message:message,
    type: type,
    insert: "top",
    container: "top-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: false,
      click: true,
      showIcon:true
    }
  });
    return { type: "no_type_required_here_go_away" };
};
