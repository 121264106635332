const allMenus = [
    {
      id: "tasks",
      label: "Tasks",
      subMenus: [
        { id: "audioTasks", label: "Audio Tasks", path: "/employee-ratings" },
      ],
    },
    {
      id: "translations",
      label: "Translations",
      subMenus: [
        { id: "translationTasks", label: "Translation Tasks", path: "/translation-dashboard" },
      ],
    },
    {
      id: "employeeManagement",
      label: "Employee Management",
      subMenus: [
        { id: "addEmployee", label: "Add Employee", path: "/add-employee" },
        { id: "basicEmployee", label: "Basic Employee Registration", path: "/employee/registration" }, // Moved here
      ],
    },
    {
        id: "adminReview", // New menu item
        label: "Admin Review",
        subMenus: [
          {
            id: "audioReReview",
            label: "Audio Re-review",
            path: "/rereview-dashboard",
          },
        ],
      },
  ];
  
  export default allMenus;