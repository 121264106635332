import React from "react";

import "./styles.css";

const ErrorState = ({ error }) => (
  <div className="state-wrapper">
    <div className="state-content">
      <h2 className="state-title">Oops! An error occurred</h2>
      <p className="state-message">{error}</p>
      <button onClick={() => window.location.reload()} className="state-button">
        Try Again
      </button>
    </div>
  </div>
);

export default ErrorState;
