import React from "react";
import ReactModal from "react-modal";
import LoadingUI from "./LoadingUI";
import PropTypes from "prop-types";

function FullpageLoader(props) {
    return (
        <ReactModal
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            isOpen={props.isOpen}
            style={customStyles}
            ariaHideApp={false}>
            <div>
                <LoadingUI message={props.message || "Please wait..."} />
            </div>
        </ReactModal>
    );
}
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "auto",
        maxWidth: "95vw",
        overflow: "hidden",
        maxHeight: "95vh",
        backgroundColor: "white",
        border: "none",
        height: "auto",
        zIndex: 2,
        padding: "20px",
        minWidth: "400px",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1,
    },
};
export default FullpageLoader;

FullpageLoader.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string,
};
