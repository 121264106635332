import React from "react";
import Modal from "react-modal";

const LanguageChangeModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        },
        content: {
          position: "relative",
          background: "white",
          borderRadius: "8px",
          width: "400px",
          margin: "auto",
          padding: 0,
          border: "none",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          inset: "unset",
        },
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            padding: "16px 20px",
            borderBottom: "1px solid #e2e8f0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0, fontSize: "1.25rem", fontWeight: 600 }}>
            Change Language?
          </h2>
          <button
            onClick={onClose}
            style={{
              background: "none",
              border: "none",
              fontSize: "1.5rem",
              cursor: "pointer",
              padding: "0 4px",
              color: "#4A5568",
              lineHeight: 1,
            }}
          >
            ×
          </button>
        </div>

        <div style={{ padding: "20px" }}>
          <p style={{ margin: 0, color: "#4A5568" }}>
            Changing the language will clear your current translation. Are you
            sure you want to continue?
          </p>
        </div>

        <div
          style={{
            padding: "16px 20px",
            borderTop: "1px solid #e2e8f0",
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            backgroundColor: "#F7FAFC",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <button
            onClick={onClose}
            style={{
              padding: "8px 16px",
              border: "1px solid #e2e8f0",
              borderRadius: "4px",
              backgroundColor: "white",
              cursor: "pointer",
              fontSize: "0.875rem",
              color: "#4A5568",
            }}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            style={{
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: "#3182ce",
              color: "white",
              cursor: "pointer",
              fontSize: "0.875rem",
              fontWeight: 500,
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LanguageChangeModal;
