import React, { Component } from "react";

class LoadingUI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLongerThanExpectedMessage: false,
        };
        this.timerId = null;
    }

    componentDidMount() {
        this.timerId = setTimeout(() => {
            this.setState({ showLongerThanExpectedMessage: true });
        }, this.props.requestTimeoutSeconds || 2500);
    }
    componentWillUnmount() {
        clearTimeout(this.timerId);
    }

    render() {
        const { showLongerThanExpectedMessage } = this.state;
        return (
            <div style={iqLoadingStyle}>
                <div style={spinnerStyle}>
                    <i style={{ color: "white", fontSize: "14px" }} className="fa fa-spin fa-spinner" />
                </div>
                <p style={{ margin: "5px 0", color: "#787878" }}>{this.props.message || "Loading..please wait"}</p>
                {showLongerThanExpectedMessage ? (
                    <p>
                        <small>Taking longer than expected...</small>
                    </p>
                ) : null}
            </div>
        );
    }
}
export default LoadingUI;

const iqLoadingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "7px 0",
    flexDirection: "column",
};

const spinnerStyle = {
    height: "30px",
    width: "30px",
    color: "white",
    background: "#333",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginBottom: "5px",
};
