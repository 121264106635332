import React, { Component } from "react";
import api from "./api";
import FullpageLoader from "./elements/FullpageLoader";
import { showNotification } from "./actions/index.actions";

class Emp_Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "",
            number: "",
            email: "",
            dob: "",
            isLoading: false,
            isError: false,
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload on form submission
        const { fullName, number, email, dob } = this.state;

        // Validation checks
        if (!fullName) {
            showNotification("Error", "Full name is missing", "danger");
            return;
        }
        if (!number) {
            showNotification("Error", "Number is missing", "danger");
            return;
        }
        if (!email) {
            showNotification("Error", "Email is missing", "danger");
            return;
        }
        if (!dob) {
            showNotification("Error", "Date of birth is missing", "danger");
            return;
        }
        if (number.length !== 10 || isNaN(number)) {
            showNotification("Error", "A valid 10-digit number is required", "danger");
            return;
        }

        this.setState({ isLoading: true }); // Show loader while processing

        try {
            const response = await api.post("/api/employee/initiate-registration", {
                fullName,
                number,
                email,
                dob,
                empID: localStorage.getItem('employeeId'),
            });

            if (response.status === 200) {
                showNotification("Success", "Employee added successfully!", "success");
                this.setState({
                    fullName: "",
                    number: "",
                    email: "",
                    dob: "",
                }); // Reset form fields
            }
        } catch (error) {
            let errorMsg = error.response?.data?.message || "Error adding employee";
            showNotification("Error", errorMsg, "danger");
        } finally {
            this.setState({ isLoading: false }); // Hide loader
        }
    };

    render() {
        const { fullName, number, email, isLoading, dob } = this.state;

        return (
            <div style={{ maxWidth: "400px", margin: "0 auto", padding: "20px" }}>
                <h2 style={{ textAlign: "center" }}>Add Employee</h2>
                <form onSubmit={this.handleSubmit}>
                    <div style={{ marginBottom: "10px" }}>
                        <label htmlFor="fullName" style={{ display: "block" }}>
                            Full Name:
                        </label>
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={fullName}
                            onChange={this.handleChange}
                            required
                            style={{ width: "100%", padding: "8px" }}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <label htmlFor="number" style={{ display: "block" }}>
                            Number:
                        </label>
                        <input
                            type="text"
                            id="number"
                            name="number"
                            value={number}
                            onChange={this.handleChange}
                            required
                            style={{ width: "100%", padding: "8px" }}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <label htmlFor="email" style={{ display: "block" }}>
                            Email:
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            required
                            style={{ width: "100%", padding: "8px" }}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <label htmlFor="dob" style={{ display: "block" }}>
                            Date of Birth:
                        </label>
                        <input
                            type="date"
                            name="dob"
                            value={dob}
                            onChange={this.handleChange}
                            style={{ width: "100%", padding: "8px" }}
                        />
                    </div>
                    <button
                        type="submit"
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "blue",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                        disabled={isLoading} // Disable button while loading
                    >
                        {isLoading ? "Submitting..." : "Submit"}
                    </button>
                </form>
                {isLoading && (
                    <FullpageLoader
                        isOpen={isLoading}
                        message="Processing... please wait"
                    />
                )}
            </div>
        );
    }
}

export default Emp_Registration;