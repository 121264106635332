import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getURL } from "../../commons/commons";
import PropTypes from "prop-types";
import api from '../../api';
/**
 * @author Suman Kumar. 
 */
function EducationLevelList(props) {
    const [isLoading, setLoader] = useState(true);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        // Define an async function inside the useEffect
        const fetchCountrylists = async () => {
            try {
                const url = getURL(
                    `/api/fetch/education-level`
                );
                const response = await api.get(url, {
                    headers: { "Cache-Control": "no-cache" },
                });
                const countryList = response.data.addressFormats;
                // const sortedData = countryList.sort((a, b) =>
                //     a.level.localeCompare(b.level)
                // );
                // Add "Not-Listed" option
                const notListedId = countryList.length ? Math.max(...countryList.map(option => option.id)) + 1 : 1;
                const notListedOption = {
                    id: notListedId,
                    level: "Not-Listed",
                };

                // Set options with "Not-Listed"
                setOptions([...countryList, notListedOption]);
                // setOptions(sortedData);
            } catch (error) {
                console.error("Error fetching country lists:", error);
            } finally {
                setLoader(false);
            }
        };

        fetchCountrylists();
    }, []);

    return (
        <Select
            options={options}
            isLoading={isLoading}
            onChange={props.onChange}
            isClearable
            value={props.value}
            placeholder= "Select Education Level"
            getOptionLabel={(option) => option.level} // Use the custom label format
            getOptionValue={(option) => option.id} // Use the custom value format
            {...props.options}
        />
    );
}

EducationLevelList.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.object, // Prop types for additional options if needed
};

export default EducationLevelList;
