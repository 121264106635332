// ReReviewModal.js
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import api from "../../api";

const ReReviewModal = ({ isOpen, onClose, onSubmit, taskResultId }) => {
  const [commentOptions, setCommentOptions] = useState([]);
  const [selectedComment, setSelectedComment] = useState("");
  const [additionalComment, setAdditionalComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCommentOptions = async () => {
      try {
        const response = await api.get("/api/employee/comment-options", {
          params: { contentType: "admin_audio" },
          headers: { "Cache-Control": "no-cache" },
        });

        // Remove the success check since your API doesn't return that structure
        setCommentOptions(response.data); // Directly use response.data since it's already an array
      } catch (error) {
        console.error("Error fetching comment options:", error);
        setError("Failed to load comment options");
      }
    };

    if (isOpen) {
      fetchCommentOptions();
    }
  }, [isOpen]);

  const handleSubmit = async (status) => {
    if (!selectedComment) {
      setError("Please select a comment");
      return;
    }

    setIsSubmitting(true);
    try {
      await onSubmit({
        reReviewId: taskResultId,
        selectedCommentOption: selectedComment,
        additionalComment,
        reReviewStatus: status,
      });
      setSelectedComment("");
      setAdditionalComment("");
      setError("");
      onClose();
    } catch (error) {
      console.error("Error submitting re-review:", error);
      setError(error.message || "Failed to submit re-review");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        },
        content: {
          position: "relative",
          background: "white",
          borderRadius: "8px",
          width: "500px",
          margin: "auto",
          padding: 0,
          border: "none",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          inset: "unset",
        },
      }}
    >
      <div style={{ width: "100%" }}>
        {/* Header */}
        <div
          style={{
            padding: "16px 20px",
            borderBottom: "1px solid #e2e8f0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0, fontSize: "1.25rem", fontWeight: 600 }}>
            Re-Review Task
          </h2>
          <button
            onClick={onClose}
            style={{
              background: "none",
              border: "none",
              fontSize: "1.5rem",
              cursor: "pointer",
              padding: "0 4px",
              color: "#4A5568",
              lineHeight: 1,
            }}
          >
            ×
          </button>
        </div>

        {/* Content */}
        <div style={{ padding: "20px" }}>
          {error && (
            <div
              style={{
                color: "#e53e3e",
                fontSize: "0.875rem",
                marginBottom: "12px",
                padding: "8px",
                backgroundColor: "#FED7D7",
                borderRadius: "4px",
              }}
            >
              {error}
            </div>
          )}

          <div style={{ marginBottom: "16px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: 500,
                color: "#2D3748",
              }}
            >
              Select Comment:
            </label>
            <select
              value={selectedComment}
              onChange={(e) => {
                setSelectedComment(e.target.value);
                setError("");
              }}
              style={{
                width: "100%",
                padding: "8px 12px",
                border: "1px solid #e2e8f0",
                borderRadius: "4px",
                fontSize: "1rem",
                backgroundColor: "white",
              }}
            >
              <option value="">Select a comment</option>
              {Array.isArray(commentOptions) &&
                commentOptions.map((option) => (
                  <option key={option.OptionID} value={option.OptionID}>
                    {option.OptionText}
                  </option>
                ))}
            </select>
          </div>

          <div style={{ marginBottom: "16px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: 500,
                color: "#2D3748",
              }}
            >
              Additional Comments:
            </label>
            <textarea
              value={additionalComment}
              onChange={(e) => setAdditionalComment(e.target.value)}
              placeholder="Type additional comments here..."
              style={{
                width: "100%",
                padding: "8px 12px",
                border: "1px solid #e2e8f0",
                borderRadius: "4px",
                fontSize: "1rem",
                minHeight: "100px",
                resize: "vertical",
                boxSizing: "border-box",
              }}
            />
          </div>
        </div>

        {/* Footer */}
        <div
          style={{
            padding: "16px 20px",
            borderTop: "1px solid #e2e8f0",
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            backgroundColor: "#F7FAFC",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <button
            onClick={onClose}
            disabled={isSubmitting}
            style={{
              padding: "8px 16px",
              border: "1px solid #e2e8f0",
              borderRadius: "4px",
              backgroundColor: "white",
              cursor: "pointer",
              fontSize: "0.875rem",
              color: "#4A5568",
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => handleSubmit(0)}
            disabled={isSubmitting}
            style={{
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: "#e53e3e",
              color: "white",
              cursor: "pointer",
              fontSize: "0.875rem",
              fontWeight: 500,
              opacity: isSubmitting ? 0.7 : 1,
            }}
          >
            {isSubmitting ? "Rejecting..." : "Reject"}
          </button>
          <button
            onClick={() => handleSubmit(1)}
            disabled={isSubmitting}
            style={{
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: "#38a169",
              color: "white",
              cursor: "pointer",
              fontSize: "0.875rem",
              fontWeight: 500,
              opacity: isSubmitting ? 0.7 : 1,
            }}
          >
            {isSubmitting ? "Accepting..." : "Accept"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReReviewModal;
