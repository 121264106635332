import React, { useState, useRef, useEffect, useCallback } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const EnhancedAudioPlayer = ({ src, onPlaybackDataChange }) => {
  const [playbackRate, setPlaybackRate] = useState(1);
  const [totalDuration, setTotalDuration] = useState(0);
  const [maxListenedDuration, setMaxListenedDuration] = useState(0);
  const audioRef = useRef(null);

  const debouncedOnPlaybackDataChange = useCallback(
    debounce((data) => {
      onPlaybackDataChange(data);
    }, 300),
    [onPlaybackDataChange]
  );

  useEffect(() => {
    if (audioRef.current?.audio?.current) {
      audioRef.current.audio.current.pause();
      setMaxListenedDuration(0);
      setTotalDuration(0);

      // Add event listener for loadedmetadata
      const audioElement = audioRef.current.audio.current;
      audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);

      // Load the audio to trigger the loadedmetadata event
      audioElement.load();

      return () => {
        audioElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      };
    }
  }, [src]);

  const handlePlaybackRateChange = useCallback((rate) => {
    setPlaybackRate(rate);
    if (audioRef.current?.audio?.current) {
      audioRef.current.audio.current.playbackRate = rate;
    }
  }, []);

  const handleListen = useCallback(() => {
    if (audioRef.current?.audio?.current) {
      const currentTime = audioRef.current.audio.current.currentTime;
      setMaxListenedDuration((prevMax) => Math.max(prevMax, currentTime));
    }
  }, []);

  const handleLoadedMetadata = useCallback(() => {
    if (audioRef.current?.audio?.current) {
      const duration = audioRef.current.audio.current.duration;
     // console.log("Audio duration:", duration); // Add this log
      setTotalDuration(duration);
      debouncedOnPlaybackDataChange({
        totalDuration: duration,
        maxListenedDuration: 0,
        lastPlayedTimestamp: new Date().toISOString(),
      });
    }
  }, [debouncedOnPlaybackDataChange]);

  useEffect(() => {
    debouncedOnPlaybackDataChange({
      totalDuration,
      maxListenedDuration,
      lastPlayedTimestamp: new Date().toISOString(),
    });
  }, [totalDuration, maxListenedDuration, debouncedOnPlaybackDataChange]);

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  return (
    <div style={styles.container}>
      <AudioPlayer
        ref={audioRef}
        src={src}
        autoPlay={false}
        style={styles.player}
        onListen={handleListen}
        onLoadedMetadata={handleLoadedMetadata}
        customControlsSection={[
          "MAIN_CONTROLS",
          "VOLUME_CONTROLS",
          <div style={styles.playbackRateContainer} key="playbackRate">
            <span>Speed: </span>
            <select
              value={playbackRate}
              onChange={(e) =>
                handlePlaybackRateChange(parseFloat(e.target.value))
              }
              style={styles.select}
            >
              <option value="0.25">0.25x</option>
              <option value="0.5">0.5x</option>
              <option value="0.75">0.75x</option>
              <option value="1">1x</option>
              <option value="1.25">1.25x</option>
              <option value="1.5">1.5x</option>
              <option value="1.75">1.75x</option>
              <option value="2">2x</option>
            </select>
          </div>,
        ]}
      />
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    marginBottom: "1rem",
  },
  player: {
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  playbackRateContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem",
  },
  select: {
    marginLeft: "0.5rem",
    padding: "0.25rem",
    borderRadius: "4px",
    border: "1px solid #e2e8f0",
  },
};

export default EnhancedAudioPlayer;
