import React, { useState, useRef, useEffect } from "react";
import "./SearchLanguageSelectCss.css";

const SearchableLanguageSelect = ({
  selectedLanguage,
  onLanguageChange,
  languageOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  // Modified filter function with more robust search
  const getFilteredLanguages = () => {
    const search = searchTerm.toLowerCase().trim();

    if (!search) return languageOptions;

    return languageOptions.filter((lang) => {
      const fullLabel = lang.label.toLowerCase();
      // Extract English name from parentheses
      const englishMatch = fullLabel.match(/\((.*?)\)/);
      const englishPart = englishMatch ? englishMatch[1].toLowerCase() : "";
      // Get native script part
      const nativePart = fullLabel.split("(")[0].trim().toLowerCase();
      // Get language code
      const code = lang.value.toLowerCase();

      // Search in all parts
      return (
        nativePart.includes(search) ||
        englishPart.includes(search) ||
        code === search
      );
    });
  };

  // Handle outside clicks
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle search input initialization
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      // Ensure search input doesn't get Keyman keyboard
      if (window.keyman) {
        try {
          window.keyman.detachFromControl(searchInputRef.current);
        } catch (err) {
          console.error("Error detaching Keyman:", err);
        }
      }

      // Add data attribute to prevent future attachment
      searchInputRef.current.setAttribute("data-kmw-disabled", "true");

      // Focus the input after ensuring Keyman is detached
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 100);
    }
  }, [isOpen]);

  const handleSelect = (language) => {
    onLanguageChange({ target: { value: language.value } });
    setIsOpen(false);
    setSearchTerm("");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    console.log("Search value:", value); // Debug log
    setSearchTerm(value);
  };

  const filteredLanguages = getFilteredLanguages();
  console.log("Filtered languages:", filteredLanguages); // Debug log

  return (
    <div className="language-select-container" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="language-select-button"
      >
        {selectedLanguage?.label || "Select Language"}
        <span className="dropdown-arrow">{isOpen ? "▲" : "▼"}</span>
      </button>

      {isOpen && (
        <div className="language-select-dropdown">
          <div className="search-container">
            <input
              ref={searchInputRef}
              type="text"
              placeholder="Search languages..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="language-search-input"
              data-kmw-disabled="true"
              onClick={(e) => e.stopPropagation()}
              autoComplete="off"
              autoFocus
            />
          </div>

          <div className="language-options">
            {filteredLanguages.length > 0 ? (
              filteredLanguages.map((lang) => (
                <button
                  key={lang.value}
                  onClick={() => handleSelect(lang)}
                  className={`language-option ${
                    selectedLanguage?.value === lang.value ? "selected" : ""
                  }`}
                >
                  {lang.label}
                </button>
              ))
            ) : (
              <div className="no-results">No languages found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableLanguageSelect;
