import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getURL } from "../../commons/commons";
import PropTypes from "prop-types";
import api from '../../api';
/**
 * @author Suman Kumar. 26 August 2024
 */
function CountrySelect(props) {
  const [isLoading, setLoader] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchCountrylists = async () => {
      try {
        const url = getURL("/api/country-list");
        const response = await api.get(url, {
          headers: { "Cache-Control": "no-cache" },
        });
        // Assuming response.data.list contains country names and ids
        const countryList = response.data.list;

        // Sort the list alphabetically by country name
        const sortedCountryList = countryList.sort((a, b) =>
          a.countryName.localeCompare(b.countryName)
        );
        setOptions(sortedCountryList);
      } catch (error) {
        console.error("Error fetching country lists:", error);
      } finally {
        setLoader(false);
      }
    };

    fetchCountrylists();
  }, []);
  return (
    <Select
      options={options}
      isLoading={isLoading}
      onChange={props.onChange}
      value={props.value}
      isClearable
      placeholder="Select Country"
      getOptionLabel={(option) => option.countryName} // Use the custom label format
      getOptionValue={(option) => option.id} // Use the custom value format
      {...props.options}
    />
  );
}

CountrySelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.object, // Prop types for additional options if needed
};

export default CountrySelect;
