import React, { useState, useEffect } from "react";
import { showNotification } from "../../actions/index.actions";
import "./TaskerProfilesSelect.css";
import api from "../../api";

const TaskerProfilesSelect = ({
  mediaType,
  selectedProfiles,
  setSelectedProfiles,
  t,
}) => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfiles = async () => {
      if (!mediaType) return;

      try {
        setLoading(true);
        const response = await api.get("/api/employee/profiles-by-media", {
          params: { mediaType },
          headers: { "Cache-Control": "no-cache" },
        });

        if (response.data.success) {
          setProfiles(response.data.data);
        } else {
          throw new Error(response.data.message || "Failed to fetch profiles");
        }
      } catch (err) {
        setError(err.message);
        showNotification(
          "Error",
          `Failed to load profiles: ${err.message}`,
          "danger"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [mediaType]);

  const handleProfileSelect = (event) => {
    const selectedId = Number(event.target.value);
    const selectedProfile = profiles.find(
      (profile) => profile.id === selectedId
    );

    if (selectedProfile && !selectedProfiles.some((p) => p.id === selectedId)) {
      setSelectedProfiles([
        ...selectedProfiles,
        {
          id: selectedId,
          description: selectedProfile.description,
        },
      ]);
      showNotification(
        "Success",
        `Added profile: ${selectedProfile.description}`,
        "success"
      );
    }
  };

  const handleRemoveProfile = (profileId) => {
    const profileToRemove = selectedProfiles.find((p) => p.id === profileId);
    setSelectedProfiles(selectedProfiles.filter((p) => p.id !== profileId));
    if (profileToRemove) {
      showNotification(
        "Success",
        `Removed profile: ${profileToRemove.description}`,
        "success"
      );
    }
  };

  if (loading)
    return <div className="profile-loading">Loading profiles...</div>;
  if (error)
    return <div className="profile-error">Error loading profiles: {error}</div>;

  return (
    <div className="profile-select-container">
      <h3 className="profile-heading">Task Profiles</h3>
      <select
        value=""
        onChange={handleProfileSelect}
        className="profile-dropdown"
      >
        <option value="" disabled>
          Select a profile
        </option>
        {profiles.map((profile) => (
          <option
            key={profile.id}
            value={profile.id}
            disabled={selectedProfiles.some((p) => p.id === profile.id)}
          >
            {profile.description}
          </option>
        ))}
      </select>

      {selectedProfiles.length > 0 && (
        <div className="selected-profiles">
          <span className="profile-label">Selected profiles:</span>
          <div className="profile-chips">
            {selectedProfiles.map((profile) => (
              <span key={profile.id} className="profile-chip">
                {profile.description}
                <button
                  onClick={() => handleRemoveProfile(profile.id)}
                  className="chip-remove"
                  type="button"
                >
                  ×
                </button>
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskerProfilesSelect;
